<script setup>
import {reactive} from 'vue'
import { useOnboarding } from '@src/modules/account/composables/useUserOnboarding'
import SideDetails from "@modules/account/views/onboarding/SideDetails.vue";

const { userCredentials } = useOnboarding()

// Defining the component local state
const state = reactive({
  business_type_list: [
    {
      key: 'solopreneur',
      value: 'Solopreneur',
      image: 'solopreneur.svg'
    },
    {
      key: 'blogger',
      value: 'Blogger',
      image: 'blogger.svg'
    },
    {
      key: 'agency',
      value: 'Agency',
      image: 'agency.svg'
    },
    {
      key: 'saas_business',
      value: 'Saas Business',
      image: 'saas.svg'
    },
    {
      key: 'marketing_team',
      value: 'Marketing Team',
      image: 'marketing.svg'
    },
    {
      key: 'enterprise',
      value: 'Enterprise',
      image: 'enterprise.svg'
    }
  ],
  account: {
    business_type: ''
  }
})

// Methods
</script>

<template>
  <div class="flex items-center w-full  lg:justify-between h-full">
    <div class="w-[60rem] max-w-[60rem] flex flex-col gap-4 bg-[#fafafb] pl-10 pt-8 rounded-tl-2xl h-full">
      <img
          src="../../assets/img/logo/contentstudio-logo-original.png"
          width="150"
          alt="ContentStudio"
      />
      <SideDetails :title="`Hi <span class='gradient-text capitalize'>${userCredentials.firstname}!</span> 👋`"
                   :sub_title="`What best describe </br> your role?`"
                   description="Your input will enable us to customize your experience to better suit your needs." />
    </div>
    <div class="w-full mt-10 lg:mt-0 px-32 flex justify-center">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
          <div v-for="(item, index) in state.business_type_list"
               :key="index" @click="userCredentials.business_type = item.key"
               :class="{'!border-[#409aff] bg-[#FAFCFF]': userCredentials.business_type === item.key}"
               class="relative flex flex-col items-center justify-between gap-y-4 border border-[#D2D5DF] group hover:!border-[#409aff] rounded-xl py-5 px-4 cursor-pointer">
            <img v-if="userCredentials.business_type === item.key" src="@assets/img/icons/onboarding/check.svg" alt="selected icon" class="absolute top-3 right-3 w-5 h-5">
            <img class="w-12 h-12" :src="require(`@assets/img/icons/onboarding/${item.image}`)" />
            <p class="font-medium text-sm text-[#757A8A] group-hover:!text-[#409aff]"
               :class="{'!text-[#409aff]': userCredentials.business_type === item.key}">
              {{ item.value }}
            </p>
          </div>
      </div>
    </div>
  </div>
</template>
