<script setup>
import CstButton from '@ui/Button/CstButton'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  sub_title: {
    type: String,
    default: ''
  }
})

// Book a demo
const handleBookADemo = () => {
  window.open('https://contentstudio.io/book-a-demo', '_blank')
}

</script>

<template>
  <div class="h-full flex flex-col justify-around pl-5">
    <!-- Body -->
    <div>
      <p v-if="title" class="text-2xl text-[#3C4549] font-bold mb-12" v-html="title"></p>
      <p v-if="sub_title" class="mb-4 text-3xl text-[#3C4549] font-bold leading-[2.8rem]" v-html="sub_title"></p>
      <p class="text-gray-700 lg:max-w-[20rem] font-normal">{{ description }}</p>
    </div>
    <!-- Buttons -->
    <div class="gap-3 buttons">
      <CstButton
          variant="primary"
          class="
        !bg-[#202324]
        !text-white
        !border-black-500 !border-2
        !rounded-lg
        !text-xs
        lg:!text-sm
        !px-4
        font-normal
      "
          @click="handleBookADemo"
      >
        <div class="flex gap-[0.625rem]">
          <img
              class="h-4 w-4"
              :src="require('@assets/img/onboarding/calendar.svg')"
              alt="calendar"
          />
          <p class="-mt-[0.8px]"> Book a demo </p>
        </div>
      </CstButton>
    </div>
  </div>
</template>

<style lang="scss">
.buttons {
  button {
    width: fit-content;
  }
}
.gradient-text {
  background-image: linear-gradient(89.24deg,
      #137EFF 0%,
      #137EFF 11.82%,
      #1DA1F2 63.31%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #3C4549; /* Fallback color */
}
</style>
