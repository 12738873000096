<script setup>
import { computed } from 'vue'
import useNumber from "@common/composables/useNumber";
import trendUp from './svgs/trend-up.vue';
import TrendDown from './svgs/trend-down.vue';
import TrendNeutral from './svgs/trend-neutral.vue';
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';


const { tooltipHtml } = useComposerHelper()
const { formatNumber } = useNumber()
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showInfoTooltip: {
    type: Boolean,
    default: true,
  },
  isReportView: {
    type: Boolean,
    default: false,
  },
})

const trends = {
  UP: 'UP',
  DOWN: 'DOWN',
  NEUTRAL: 'NEUTRAL',
}

const trend = computed(() => {
  const data = props.data

  if (data.difference > 0) {
    return trends.UP
  } else if (data.difference < 0) {
    return trends.DOWN
  } else {
    return trends.NEUTRAL
  }
})

const getStatisticClass = computed(() => {
  if (trend.value === trends.UP) {
    return 'text-[#3D8A61]'
  }

  if (trend.value === trends.DOWN) {
    return 'text-[#e02f2f]'
  }

  return 'text-[#69788E]'
})

const growth = computed(() => {
  const data = props.data
  return data.growth === 'N/A' ? data?.growth : `${Number.isNaN(data?.growth) ? '-' : data?.growth}%`
})

const formattedValue = computed(()=>{
  const data = props.data
  return data?.total === 'N/A' ? data?.total : formatNumber(data?.originalValue)
})

</script>

<template>
  <div
    class="grid w-full border-solid border-b border-t-0 border-r-0 border-l-0 py-3"
    :class="{ 'grid-cols-1 xl:grid-cols-7 gap-3 2xl:gap-1': !isReportView, 'grid-cols-7 gap-3 2xl:gap-1': isReportView }"
  >
    <div
    class="break-words"
    :class="{ 'xl:col-span-3 ': !isReportView, 'col-span-3': isReportView }"
    >
      <span
        v-tooltip="{
          content: tooltipHtml(data?.tooltip),
          theme: 'light',
          allowHTML: true,
        }"
         class="cursor-pointer text-dark-cs-100 text-base max-w-[100px] break-words ">
        {{data?.title}}
      </span>
    </div>
    <div
      v-tooltip="{
        content: tooltipHtml(data?.originalValue),
        theme: 'light',
        allowHTML: true,
      }"
      class="text-secondary-cs text-base font-medium flex items-center xl:justify-center"
      :class="{ 'xl:col-span-2': !isReportView, 'col-span-2': isReportView }"
      >
      {{formattedValue}}
    </div>

    <div
    class="flex items-center gap-1 flex-wrap 2xl:justify-center"
    :class="{ 'xl:col-span-2': !isReportView, 'col-span-2': isReportView }"
    >
      <div class="text-base font-medium" :class="getStatisticClass">
        {{growth}}
      </div>
      <div v-if="trend === trends.UP ">
        <trendUp class="w-2 h-3" />
      </div>
      <div v-else-if="trend === trends.DOWN ">
        <TrendDown class="w-2 h-3" />
      </div>
      <div v-else>
        <TrendNeutral class="w-2 h-3" />
      </div>
    </div>
  </div>
</template>
