import { computed, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import {
  saveWhiteLabelDomainSettings,
  validateWhiteLabelDomainSettings,
} from '@src/modules/setting/config/api-utils'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'

const fields = reactive({
  whiteLabelDomain: { value: '', error: '', isRequired: true },
  dnsType: { value: 'CNAME', error: '', isRequired: false },
  domainHost: {
    value: process.env.WHITELABEL_HOST ?? 'whitelabel-qa',
    error: '',
    isRequired: false,
  },
  domainValue: {
    value: process.env.WHITE_LABEL_VALUE ?? 'whitelabel-qa',
    error: '',
    isRequired: false,
  },
})

const isLoading = ref(false)

export default function useDomainSettings() {
  const { getters } = useStore()

  const { whiteLabelId, domainSettings, transformDomainSettings, showToast } =
    useWhiteLabel()

  const isFormValid = computed(() => {
    return Object.values(fields).every(
      (field) => !field.isRequired || (field.value && !field.error)
    )
  })

  const shouldDisableNext = computed(
    () => isLoading.value || !isFormValid.value
  )

  watch(
    () => domainSettings.value,
    (newVal) => {
      setDomainSettings(newVal)
    }
  )

  function setDomainSettings(settings = {}) {
    if (settings) {
      // reset errors
      clearErrors()

      fields.whiteLabelDomain.value = settings.whiteLabelDomain || ''
    }
  }

  const setErrors = async (error = {}) => {
    await showToast(`${error.response?.data?.message}`, 'error')

    const errors = error.response?.data?.errors || []

    if (errors.domain) fields.whiteLabelDomain.error = errors.domain[0]
  }

  const clearErrors = () => {
    Object.values(fields).forEach((field) => {
      field.error = ''
    })
  }

  const getPayload = () => {
    const payload = {
      domain: fields.whiteLabelDomain.value,
      workspace_id: getters.getActiveWorkspace._id,
    }

    if (whiteLabelId.value) {
      payload.id = whiteLabelId.value
    }

    return payload
  }

  const validateDomain = async () => {
    clearErrors()

    if (!fields.whiteLabelDomain.value) {
      fields.whiteLabelDomain.error = 'Domain is required'
      return
    }

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(
        validateWhiteLabelDomainSettings,
        payload
      )
      const message = data.status
        ? 'Domain is verified & saved successfully'
        : data.message
      const type = data.status ? 'success' : 'warning'

      await showToast(message, type)
      return data.status
    } catch (error) {
      await setErrors(error)
      return false
    } finally {
      isLoading.value = false
    }
  }

  const handleSubmit = async () => {
    clearErrors()

    isLoading.value = true

    if (!(await validateDomain())) {
      isLoading.value = false
      return
    }

    const payload = getPayload()

    try {
      const { data } = await proxy.post(saveWhiteLabelDomainSettings, payload)
      if (!data.status) {
        await showToast(data.message, 'warning')
      } else {
        const formattedData = transformDomainSettings(data.whitelabel)
        setDomainSettings(formattedData)
      }
      return data.status
    } catch (error) {
      await setErrors(error)

      return false
    } finally {
      isLoading.value = false
    }
  }

  return {
    ...toRefs(fields),
    isLoading,
    shouldDisableNext,

    validateDomain,
    handleSubmit,
  }
}
