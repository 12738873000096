<template>
  <div class="h-screen overflow-x-hidden">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[4.6rem] px-40">
        <p class="text-[#3A4557] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign In</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-3xl">Reset your password</h2>
              <p class="text-md text-[#757A8A] mt-5">Enter your email address below and we’ll get you back on track.</p>
            </div>

            <div class="flex flex-col gap-2 mt-12">

              <!-- Email -->
              <CstFloatingLabelInput
                  id="email"
                  v-model="email"
                  type="email"
                  label="Email Address"
                  show-icon-left
                  class="!h-[3.3rem] mb-2.5"
                  >
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  :disabled="forgotPasswordLoader"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  type="submit"
                  @click.prevent="forgotPassword"
              >
                <span class="w-full">Send
                  <img
                      v-if="forgotPasswordLoader"
                      style="width: 20px; margin-left: 8px"
                      src="../assets/img/common/gif_loader_white.gif"
                      alt=""
                  />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forgotPasswordURL } from '@src/config/api-utils'
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput";
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");

export default {
  name: 'ForgotPassword',
  components: {
    LoginSideComponent,
    CstFloatingLabelInput
  },
  data () {
    return {
      email: '',
      forgotPasswordLoader: false,
      message: false
    }
  },
  methods: {
    forgotPassword () {
      this.message = false
      this.forgotPasswordLoader = true
      this.$http
        .post(forgotPasswordURL, {
          email: this.email
        })
        .then(
          (response) => {
            this.forgotPasswordLoader = false
            if (response.data.status) {
              this.alertMessage(response.data.message)
            } else {
              if (response.data.message === 'invited') this.message = true
              else this.alertMessage(response.data.message, 'error')
            }
          },
          (response) => {}
        )
    }
  }
}
</script>

