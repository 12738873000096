<template>
  <div
    class="z-[1050] flex flex-col flex-1 w-full min-w-[30rem] max-w-[30rem] h-full bg-[#FFFFFF] p-0 rounded-lg relative right-[-3px]"
    :class="{ 'right-[0] !rounded-none': props.type === 'blog' }"
    style="
      border: 1px solid rgba(210, 213, 223, 0.5);
      border-right: 0;
      box-shadow: 0 2px 50px 0 rgba(0, 39, 114, 0.15);
    "
  >
    <!-- header -->
    <div
      v-tooltip="{ content: 'Collapse' }"
      class="absolute -left-3 top-[1rem] rounded-full w-[1.875rem] h-[1.875rem] flex justify-center items-center cursor-pointer bg-white z-[20000] border"
      @click="props.toggleChatDialogs('openSavedPrompts')"
    >
      <i class="cs-angle-right w-[0.688rem] text-[#979CA0]"></i>
    </div>
    <div
      class="w-full min-h-[60px] h-[60px] bg-gray-300 flex items-center justify-between px-5 bg-opacity-30"
    >
      <span class="ml-3 text-lg font-semibold text-black-900 mt-0.5"
        >Prompts</span
      >
      <!-- search -->
      <div class="relative w-max flex items-center">
        <input
          v-model="state.searchTerm"
          type="text"
          placeholder="Search"
          class="chatInput bg-transparent min-w-[16rem] flex items-center justify-between px-4 py-2 rounded-full"
          style="border: 0.031rem solid #757a8a66"
        />
        <img
          v-if="state.searchTerm === ''"
          src="@src/assets/img/chat_bot/search-icon.svg"
          width="20"
          height="20"
          class="absolute right-3"
          alt=""
        />
        <i
          v-else
          class="fas fa-times-circle absolute cursor-pointer opacity-80 right-3"
          @click="
            () => {
              state.searchTerm = ''
            }
          "
        ></i>
      </div>
    </div>
    <div class="overflow-y-scroll h-full scrollable-div">
      <!-- custom prompts -->
      <div class="flex justify-between items-center w-full bg-white"
      style="border-bottom: 0.031rem solid rgba(221, 227, 231, 0.5)">
        <div
          class="w-full bg-white pt-4 pb-3 px-4 flex items-center justify-between sticky top-0 z-99"
          :class="state.customPrompts.length > 0 && 'cursor-pointer'"

          @click="toggleCustomPrompt"
        >
          <div class="flex items-center">
            <span
              class="text-sm font-medium text-black-900 select-none"
              :class="{ '!text-cs-primary': state.toggle }"
            >
              Custom
              <span class="mx-1">({{ state.customPrompts.length }})</span>
            </span>
            <div
              class="mx-1.5 w-7 h-7 bg-cs-primary text-white font-normal rounded flex items-center justify-center z-99 cursor-pointer"
              @click.stop="toggleCustomPromptInput"
            >
              <i v-if="!state.showCustomPromptInput" class="far fa-plus"></i>
              <i v-else class="far fa-minus"></i>
            </div>
          </div>
          <!-- Not Show toogle for custom prompts -->
          <!-- <i
            v-if="(state.customPrompts.length > 0 || state.showCustomPromptInput)"
            class="fa fa-chevron-down cursor-pointer text-xs"
            :class="{
              'rotate-180': state.customToggle || state.showCustomPromptInput,
            }"
          ></i> -->
        </div>
        <div class="inline-flex w-full justify-end items-center gap-3 mx-4">
        <!-- Toggle Switch -->
          <span
            class="text-sm font-medium text-black-900"
          >
            Favourite
          </span>

          <div
          class="flex items-center rounded-full px-1 py-2.5 duration-300 ease-in-out w-[30px] h-[20px] cursor-pointer"
          :class="state.showFavoritePrompts ? 'bg-[#549AEE]' : 'bg-gray-300'"
          @click="toggleAllFavourite"
          >
            <div
              :class="[
                'rounded-full shadow-md border-solid border-white transform duration-300 ease-in-out border-2 w-[12px] h-[12px] bg-white',
                state.showFavoritePrompts ? 'translate-x-3' : 'translate-x-0'
              ]"
            ></div>
          </div>
        </div>
      </div>

      <div class="show-favourite pl-4 pr-4 pb-0.5 pt-2"
        v-if="state.showFavoritePrompts"
      >
        <template v-for="item in getAllFavourites" :key="item._id">
          <div
            class="cursor-pointer hover:text-cs-primary transition-all bg-[#F4F6FA] rounded-lg p-3 mb-3 flex justify-between"
          >
            <div
              class="block w-full"
            >
              <span class="block text-[#3C4549] text-sm font-medium">{{
                item.title
              }}</span>
              <span class="block mt-2 text-[#757A8A] text-sm font-normal">{{
                item.prompt
              }}</span>
            </div>

            <span
              class="flex justify-center mt-1"
              @click.stop="toggleFavorite(item , item.is_default == '1' ? 'default' : 'custom')"
            >
              <i
                style="font-size: 14px;"
                class="fa-star !shadow-none rounded-md"
                :class="item.is_favourite ? 'fas text-[#0068e5]' : 'far text-[#8B8B8B]'"
              ></i>
            </span>
          </div>
        </template>
        <template v-if="!getAllFavourites?.length">
          <div class="flex justify-center items-center w-full h-[70vh]">
            <span  class="block text-center text-lg">No favourites prompt found!</span>
          </div>
        </template>
      </div>
      <div v-else>
        <div
          v-show="
            (state.customToggle && state.customPrompts.length) ||
            state.showCustomPromptInput
          "
          class="pl-4 pr-4 pb-0.5 pt-2"
        >
          <!-- custom prompt inputs -->
          <div
            v-if="state.showCustomPromptInput"
            class="w-full mb-4 rounded-xl"
            style="
              box-shadow: 0 1px 15px rgba(204, 209, 224, 0.5);
              border: 1px solid #eceef5;
            "
          >
            <input
              ref="titleInput"
              type="text"
              placeholder="Write your title here"
              class="chatInput !border-0 rounded-xl w-100 p-3 text-sm text-[#3C4549] leading-5 font-medium"
              @keyup="titleChange"
            />
            <textarea
              ref="promptTextInput"
              type="text"
              placeholder="Write prompt here"
              class="chatInput min-h-[19px] !border-0 w-100 px-3 text-sm leading-5 text-[#757A8A] placeholder:text-[#BABDC5]"
              rows="1"
              @keyup="promptChange"
            />
            <div
              class="w-100 flex justify-end items-center p-3"
              style="border: 1px solid #eceef5; border-radius: 1px"
            >
              <button
                class="text-[#2961D2] text-sm font-normal rounded px-4 py-2 cursor-pointer bg-white hover:!bg-[#E3F2FF]"
                style="border: 1px solid #0068e5"
                @click="resetInputValues"
              >
                Cancel
              </button>
              <button
                class="bg-cs-primary text-white text-sm font-normal rounded px-4 py-2 cursor-pointer flex items-center gap-x-2 hover:bg-opacity-75 ml-2"
                style="border: 1px solid #0068e5"
                :class="{
                  ' !bg-blue-400 ':
                    state.promptLoader ||
                    (state.isEdit && !state.isEditableStateMutated),
                }"
                :disabled="
                  state.promptLoader ||
                  (state.isEdit && !state.isEditableStateMutated)
                "
                @click="addCustomPrompt"
              >
                {{ state.isEdit ? 'Update' : 'Save' }}
                <img
                  v-if="state.promptLoader"
                  class="w-5 mx-0.5"
                  src="@src/assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </div>
          <!-- custom prompt list -->
          <template v-if="state.customPrompts.length && state.showCustomPrompts">
            <template>
              <template v-for="item in state.customPrompts" :key="item._id">
                <div
                  class="cursor-pointer hover:text-cs-primary transition-all bg-[#F4F6FA] rounded-lg p-3 mb-3 flex justify-between"
                >
                  <div
                    class="block w-full"
                    @click="props.handleCustomPrompts(item.prompt, 'append')"
                  >
                    <span class="block text-[#3C4549] text-sm font-medium">{{
                      item.title
                    }}</span>
                    <span class="block mt-2 text-[#757A8A] text-sm font-normal">{{
                      item.prompt
                    }}</span>
                  </div>

                  <span
                    class="flex justify-center mt-1"
                    @click.stop="toggleFavorite(item)"
                  >
                    <i
                      style="font-size: 14px;"
                      class="fa-star !shadow-none rounded-md"
                      :class="item.is_favourite ? 'fas text-[#0068e5]' : 'far text-[#8B8B8B]'"
                    ></i>
                  </span>

                  <div class="group relative">
                    <img
                      src="@src/assets/img/chat_bot/more-vertical-black.svg"
                      width="18"
                      height="18"
                      alt="more-vertical.svg"
                      class="ml-3 z-10 cursor-pointer active:opacity-50"
                    />
                    <!--  DROPDOWN-->
                    <div
                      class="w-[160px] flex-col p-4 absolute -right-3 rounded top-7 z-[1049] bg-white hidden group-hover:!flex"
                      style="
                        filter: drop-shadow(
                          0px 3px 20px rgba(201, 207, 224, 0.4)
                        );
                        border-radius: 10px;
                      "
                    >
                      <img
                        src="@src/assets/img/chat_bot/top-edge.svg"
                        alt="arrow"
                        class="absolute -top-2 -right-2"
                      />
                      <div
                        class="cursor-pointer hover:font-bold"
                        @click="updateCustomPrompt(item)"
                      >
                        <img
                          src="@src/assets/img/chat_bot/handwitting.svg"
                          alt="external-link"
                          width="18"
                          height="18"
                        />
                        <span class="text-[#3C4549] text-sm ml-3">Edit</span>
                      </div>
                      <div
                        style="border-bottom: 1px solid #f2f3f8"
                        class="my-2"
                      ></div>
                      <div
                        class="cursor-pointer hover:font-bold"
                        @click="deleteCustomPrompt(item._id)"
                      >
                        <img
                          src="@src/assets/img/chat_bot/trash-2.svg"
                          alt="external-link"
                          width="18"
                          height="18"
                        />
                        <span class="text-[#D65151] text-sm ml-3">Delete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <!-- default prompts -->
        <template v-if="state.defaultPrompts.length > 0">
          <template >
            <PromptTemplate
              v-for="(prompt, index) in state.defaultPrompts"
              :search-term="state.searchTerm"
              :key="index"
              :category="prompt"
              :handle-custom-prompts="props.handleCustomPrompts"
              :toggle-favorite="toggleFavorite"
            ></PromptTemplate>
          </template>
        </template>
      </div>

      <template v-if="showEmptyMessage">
        <span  class="block text-center text-lg"
          >No template prompt found!</span
        >
      </template>

    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules

import {
  defineProps,
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from 'vue'
import { AiChatPrompts } from '@src/modules/AI-tools/Prompts.js'
import PromptTemplate from '@src/modules/AI-tools/PromptTemplate.vue'
import proxy from '@common/lib/http-common'
import {
  deleteCustomPromptUrl,
  fetchCustomPromptsUrl,
  saveCustomPromptUrl,
} from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import { getFavoritePrompts } from '@common/lib/helper'
const store = useStore()
// components
const props = defineProps({
  type: {
    type: String,
    default: 'modal',
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
})
const state = reactive({
  customPrompts: [],
  defaultPrompts: [],
  promptLoader: false,
  customToggle: true,
  isEdit: false,
  editableState: {},
  isEditableStateMutated: false,
  showCustomPromptInput: false,
  showCustomPrompts: true,
  activePrompt: '',
  searchTerm: '',
  showFavoritePrompts: false,
})

const toggleCustomPromptInput = () => {
  state.showCustomPromptInput = !state.showCustomPromptInput
  state.isEdit = false

  if (state.showCustomPromptInput) {
    state.customToggle = true
  }
}

const toggleCustomPrompt = () => {
  state.customToggle = !state.customToggle

  if (!state.customToggle) {
    state.showCustomPromptInput = false
  }
}

const toggleAllFavourite = () => {
  state.showFavoritePrompts = !state.showFavoritePrompts
}

const showEmptyMessage = computed(() => {
  return !state.customPrompts.length && !state.defaultPrompts.length && !state.showFavoritePrompts
})

const getAllFavourites = computed(() => {
  return  getFavoritePrompts(state.defaultPrompts, state.customPrompts)
})

const titleInput = ref(null)
const promptTextInput = ref(null)

onBeforeMount(() => {
  fetchCustomPrompts()
})

onMounted(() => {
  state.customToggle = true
  EventBus.$on('save-user-prompt', (prompt) => {
    props.toggleChatDialogs('closeHistory')
    state.customToggle = true
    state.showCustomPromptInput = true
    setTimeout(() => {
      if (promptTextInput.value) {
        promptTextInput.value.value = prompt
        promptTextInput.value.focus()
      }
    }, 1)
  })
})

onUnmounted(() => {
  EventBus.$off('save-user-prompt')
})
watch(
  () => store.getters.getWorkspaces.activeWorkspace._id,
  () => {
    fetchCustomPrompts()
  }
)
watch(
  () => state.searchTerm,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      fetchCustomPrompts({ search: newVal });
    }
  }
);
const resetInputValues = (inputBox = true) => {
  titleInput.value.value = ''
  promptTextInput.value.value = ''
  state.activePrompt = ''
  state.editableState = {}
  state.isEditableStateMutated = true
  state.isEdit = false
  if (inputBox) {
    state.showCustomPromptInput = false
  }
}
const addCustomPrompt = () => {
  if (titleInput.value.value && promptTextInput.value.value) {
    saveCustomPrompt({
      title: titleInput.value.value,
      prompt: promptTextInput.value.value,
    })
    return
  }
  store.dispatch('toastNotification', {
    message: 'Prompt and title are required!',
    type: 'info',
  })
}

const titleChange = (e) => {
  if (state.editableState.title) {
    if (
      state.editableState.title === e.target.value &&
      state.editableState.prompt === promptTextInput.value.value
    ) {
      state.isEditableStateMutated = false
    } else {
      state.isEditableStateMutated = true
    }
  }
}

const promptChange = (e) => {
  if (state.editableState.prompt) {
    if (
      state.editableState.prompt === e.target.value &&
      state.editableState.title === titleInput.value.value
    ) {
      state.isEditableStateMutated = false
    } else {
      state.isEditableStateMutated = true
    }
  }
}

const fetchCustomPrompts = (payload) => {
  if(store?.getters?.getWorkspaces?.activeWorkspace?._id){
    proxy
      .post(fetchCustomPromptsUrl, {
        workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
        search: payload?.search || ''
      })
      .then((response) => {
        if (response.data.status) {
          state.customPrompts = response.data?.data?.custom_prompts,
          state.defaultPrompts = response.data?.data?.default_prompts;

          if(!payload?.search){
            EventBus.$emit(`${props.type}-update-prompts`, response.data?.data)
          }
        }
      })
      .catch((error) => {
        console.debug(error)
        store.dispatch('toastNotification', {
          message: error.response?.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
      })
  }
}

const toggleFavorite = (prompt, type = 'custom') => {
  const updatedPrompt = { ...prompt, is_favourite: !prompt.is_favourite }
  state.activePrompt = prompt._id
  saveCustomPrompt(updatedPrompt, 'favourite', type)
}

const saveCustomPrompt = (value, action = 'save' , type = 'custom') => {
  if (state.promptLoader) return
  state.promptLoader = true
  const payload = {
    workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
    title: value.title,
    prompt: value.prompt,
    type: type,
  }

  if (action === 'favourite') {
    payload.is_favourite = value.is_favourite
  }

  if (state.activePrompt) {
    payload._id = state.activePrompt
  }
  proxy
    .post(saveCustomPromptUrl, payload)
    .then((response) => {
      if (response.data.status) {
        if (state.activePrompt) {
          if(type == 'default'){
            state.defaultPrompts = state.defaultPrompts.map((item) => {
              item.prompts = item.prompts.map((prompt) => {
                if (prompt._id === state.activePrompt) {
                  return {
                    ...prompt,
                    is_favourite: response.data.data?.is_favourite,
                    color_code: response.data.data?.color_code
                  };
                }
                return prompt
              })
              return item
            })
          }else{
            state.customPrompts = state.customPrompts.map((item) => {
              if (item._id === state.activePrompt) {
                return response.data.data
              }
              return item
            })
          }
        } else {
          state.customPrompts.push(response.data.data)
        }
        EventBus.$emit(`${props.type}-update-prompts`, {
          custom_prompts: state.customPrompts,
          default_prompts: state.defaultPrompts,
        })
        state.promptLoader = false

        if (action === 'favourite') {
          state.activePrompt = ''
        } else {
          resetInputValues(false)

          const message = `Prompt ${
            state.activePrompt ? 'updated' : 'saved'
          } successfully!`
          store.dispatch('toastNotification', {
            message,
            type: 'success',
          })
        }
      } else {
        store.dispatch('toastNotification', {
          message: response.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
        state.promptLoader = false
      }
    })
    .catch((error) => {
      console.debug(error)
      state.promptLoader = false
      store.dispatch('toastNotification', {
        message: error.response?.data?.message || UNKNOWN_ERROR,
        type: 'error',
      })
    })
}
const updateCustomPrompt = (prompt) => {
  state.activePrompt = prompt._id
  state.customToggle = true
  state.showCustomPromptInput = true
  state.isEdit = true
  state.editableState.title = prompt.title
  state.editableState.prompt = prompt.prompt
  state.isEditableStateMutated = false
  setTimeout(() => {
    if (titleInput.value) {
      titleInput.value.value = prompt.title
      promptTextInput.value.value = prompt.prompt
    }
  }, 1)
}
const deleteCustomPrompt = (id) => {
  proxy
    .post(deleteCustomPromptUrl, {
      workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
      _id: id,
    })
    .then((response) => {
      if (response.data.status) {
        state.customPrompts = state.customPrompts.filter(
          (item) => item._id !== id
        )
        EventBus.$emit(`${props.type}-update-prompts`, {
          custom_prompts: state.customPrompts,
          default_prompts: state.defaultPrompts,
        })
        store.dispatch('toastNotification', {
          message: 'Prompt deleted successfully!',
          type: 'success',
        })
      }
    })
    .catch((error) => {
      console.debug(error)
      store.dispatch('toastNotification', {
        message: error.data?.message || UNKNOWN_ERROR,
        type: 'error',
      })
    })
}
</script>

<style lang="css" scoped>
.scrollable-div {
  position: relative;
  width: 100%;
  overflow: scroll;
}

.scrollable-div::-webkit-scrollbar {
  width: 10px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-div::-webkit-scrollbar-track:hover {
  border-left: 1px solid #f4f6fa;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: white;
  border: 0;
}
.scrollable-div:hover::-webkit-scrollbar-thumb {
  background-color: #dbdcdd;
  border-radius: 0;
}
</style>
