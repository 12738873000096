<script setup>
import { inject } from 'vue'
import OutcomeStatus from '@src/components/states/OutcomeStatus.vue'

defineProps({
  status: {
    type: Boolean,
    default: false,
  },
})

const root = inject('root')
const { $bvModal } = root
</script>

<template>
  <b-modal
    id="white-label-complete"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="flex items-center pt-5 w-full">
      <p class="font-medium text-2xl text-center w-full"
        >Whitelabel Setup Completed</p
      >
      <div
        class="text-gray-900 rounded-full w-8 h-8 flex items-center justify-center hover:bg-gray-100 active:bg-gray-300"
      >
        <img
          alt="close popup"
          class="cursor-pointer h-6 w-6"
          src="@src/assets/img/integration/cross_dark_gray.svg"
          @click="$bvModal.hide('white-label-complete')"
        />
      </div>
    </div>
    <OutcomeStatus :status="status ? 'success' : 'fail'" />
  </b-modal>
</template>

<style scoped></style>
