/**
 *  This file is composable conversion of analyticsUtilsMixin (src/modules/analytics/components/common/analyticsUtilsMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
import { useStore } from '@state/base'
import useString from '@/src/modules/common/composables/useString'
import usePlatform from '@/src/modules/common/composables/usePlatform'

export default function useAnalyticsUtils() {
  const { getters } = useStore()
  const { capitalizeFirstLetter } = useString()
  const { isIGPersonalAccount } = usePlatform()

  /**
   * @name selectAllAccounts
   * @returns {Array} List of all accounts from all platforms
   * @description Get all accounts from all platforms
   * @example selectAllAccounts()
   */
  function selectAllAccounts(ignorePlatforms = []) {
    const platforms = [
      'facebook',
      'instagram',
      'linkedin',
      'tiktok',
      'twitter',
      'youtube',
      'pinterest',
    ]
    let accountsList = []
    for (const platform in platforms) {
      if (!ignorePlatforms.includes(platforms[platform])) {
        accountsList = accountsList.concat(
          getPlatformAccounts(platforms[platform])
        )
      }
    }
    return accountsList
  }

  /**
   * @name getPlatformAccounts
   * @param {String} platform
   * @returns {Array} List of accounts from a specific platform
   * @description Get accounts list from a specific platform
   * @example getPlatformAccounts('facebook')
   */
  function getPlatformAccounts(platform) {
    const accountsList = []
    switch (platform) {
      case 'facebook':
        getters.getFacebookPagesList.forEach((item) => {
          item.account_type = 'facebook'
          accountsList.push(item)
        })
        break
      case 'twitter':
        getters.getTwitterAccounts.items.forEach((item) => {
          if (item?.developer_app?.analytics_enabled) {
            item.account_type = 'twitter'
            accountsList.push(item)
          }
        })
        break
      case 'linkedin':
        getters.getLinkedinAccounts.items.forEach((item) => {
          item.account_type = 'linkedin'
          if (typeof item.linkedin_id !== 'string') {
            accountsList.push(item)
          }
        })
        break
      case 'instagram':
        getters.getInstagramAccounts.items.forEach((item) => {
          item.account_type = 'instagram'
          accountsList.push(item)
        })
        break
      case 'pinterest':
        getters.getPinterestBoards.forEach((item) => {
          item.account_type = 'pinterest'
          accountsList.push(item)
        })
        break
      case 'tiktok':
        getters.getTiktokAccounts.items.forEach((item) => {
          item.account_type = 'tiktok'
          accountsList.push(item)
        })
        break
      case 'youtube':
        getters.getYoutubeAccounts?.items?.forEach((item) => {
          item.account_type = 'youtube'
          accountsList.push(item)
        })
        break
      case 'gmb':
        getters.getGmbAccounts.items.forEach((item) => {
          item.account_type = 'gmb'
          accountsList.push(item)
        })
        break
      case 'tumblr':
        getters.getTumblrAccounts.items.forEach((item) => {
          item.account_type = 'tumblr'
          accountsList.push(item)
        })
        break
    }

    return accountsList
  }
  /***
   * @name getProfileType
   * @param {String} accountType
   * @param {Object} account
   * @returns {String} Profile type of the account
   * @description Get profile type of the account based on account type
   */
  function getProfileType(accountType, account = null) {
    switch (accountType) {
      case 'instagram':
        return isIGPersonalAccount(account) ? 'Personal' : 'Business'
      case 'facebook':
        return 'Page'
      case 'pinterest':
        return account?.type || 'Unknown Type'
      case 'linkedin':
        return account ? account.type : 'Page'
      case 'tiktok':
        return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
      case 'youtube':
        return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
      case 'tumblr':
        return account ? account.type : 'Page'
      default:
        return 'Page'
    }
  }
  /**
   * @name getAccountId
   * @param {Object} account
   * @returns {String} Account ID
   * @description Get account ID based on account type
   *
   */
  function getAccountId(account) {
    switch (account.account_type) {
      case 'facebook':
        return account.facebook_id
      case 'twitter':
        return account.twitter_id
      case 'instagram':
        return account.instagram_id
      case 'pinterest':
        return account.board_id
      case 'linkedin':
        return account.linkedin_id?.toString()
      case 'tiktok':
        return account.platform_identifier
      case 'youtube':
        return account.platform_identifier
    }
  }

  /**
   * @name getAccountById
   * @param {String} accountId
   * @returns {Object} Account details
   * @description Get account details based on account ID
   *
   */

  function getAccountById (accountId) {
    const allAccounts = selectAllAccounts()
    // eslint-disable-next-line eqeqeq
    return allAccounts.find((account) => getAccountId(account) == accountId)
  }

  return {
    selectAllAccounts,
    getPlatformAccounts,
    getProfileType,
    getAccountId,
    getAccountById,
  }
}
