<template>
  <div id="gSignInWrapper">
    <div id="customBtn" class="customGPlusSignIn" @click="handleSignIn">
        <span class="icon"></span>
        <span class="buttonText">Google</span>
    </div>
  </div>
</template>

<script setup>
import {onMounted} from 'vue'

const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_SSO_CLIENT_ID
const GOOGLE_LOGIN_URI = `${process.env.VUE_APP_CS_BACKEND_URL}google/sso`

const handleSignIn = () => {
  const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
  const params = new URLSearchParams({
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: GOOGLE_LOGIN_URI,
    response_type: 'code',
    scope: 'email profile',
    prompt: 'select_account',
    access_type: 'offline'
  })

  window.location.href = `${baseUrl}?${params.toString()}`
}

onMounted(() => {
  // No need to load Google script for this approach
})
</script>

<style scoped>
#gSignInWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.customGPlusSignIn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  color: #444;
  border-radius: 7px;
  border: 1px solid #e5e7eb;
  white-space: nowrap;
  padding: 5px;
  cursor: pointer;
  width: 100%;
}

.icon {
  background: url('https://developers.google.com/identity/images/g-logo.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 38px;
  height: 38px;
  background-size: 20px;
  background-position: center;
}

.buttonText {
  color: #3A4557;
  font-size: 13.5px;
}
.buttonText:hover {
  color: #0068E5;
}
</style>
