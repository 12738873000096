<script setup>
import { watch } from 'vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import TopPostCard from '@/src/modules/analytics/views/facebook_v2/components/TopPostCard.vue'
import EngagementIcon from "@/src/assets/img/icons/analytic/plain/total-engagement.svg";
import ReactionsIcon from "@/src/assets/img/icons/analytic/plain/reactions.svg";
import CommentsIcon from "@/src/assets/img/icons/analytic/plain/comments-icon.svg";
import SharesIcon from "@/src/assets/img/icons/analytic/plain/shares.svg";
import ImpressionsIcon from "@/src/assets/img/icons/analytic/plain/post-impressions.svg";
import PostClickIcon from "@/src/assets/img/icons/analytic/plain/post-clicks.svg";
import PostTypeIcon from "@/src/assets/img/icons/analytic/plain/post-type.svg";

const { routes, selectedAccount , overviewTopPostsData, isReportView, isLoadingStates, mediaTypeMap } = useFacebookAnalytics()

const rows = [
  {
    icon: EngagementIcon,
    title: 'Engagement',
    api_key: 'total_engagement',
    iconClass: 'scale-[1.2]'
  },
  {
    icon: ReactionsIcon,
    title: 'Reactions',
    api_key: 'total',
    iconClass: ''
  },
  {
    icon: CommentsIcon,
    title: 'Comments',
    api_key: 'comments',
    iconClass: ''
  },
  {
    icon: SharesIcon,
    title: 'Shares',
    api_key: 'shares',
    iconClass: ''
  },
  {
    icon: ImpressionsIcon,
    title: 'Impressions',
    api_key: 'post_impressions',
    iconClass: ''
  },
  {
    icon: PostClickIcon,
    title: 'Clicks',
    api_key: 'post_clicks',
    iconClass: ''
  },
  {
    icon: PostTypeIcon,
    title: 'Post Type',
    api_key: 'media_type',
    iconClass: ''
  },
]

const getMediaType = (mediaType) => mediaTypeMap[mediaType] || mediaType

watch(() => overviewTopPostsData.value, () => {
  if (overviewTopPostsData.value && overviewTopPostsData.value.length) {
    overviewTopPostsData.value.forEach((post) => {
      post.media_type = getMediaType(post?.media_type?.toLowerCase())
    })
  }
},{
  immediate: true,
  deep: true
})

</script>

<template>
   <AnalyticsCardWrapper type="fb-top-posts">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Top Posts
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Reach is the number of people who saw your post. It is a unique metric that measures the number of people who saw your content. It is important to track reach because it helps you understand how many people are seeing your content and how well it is performing.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="">
      <template v-if="!isLoadingStates[routes?.OVERVIEW_TOP_POSTS] && overviewTopPostsData?.length === 0">
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </template>
      <template v-else>
        <div
          class="grid  gap-5 "
          :class="{ 'grid-cols-3': isReportView, 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[535px] overflow-hidden': !isReportView }"
        >
          <template
            v-if="isLoadingStates[routes?.OVERVIEW_TOP_POSTS]"
          >
            <template v-for="i in [1,2,3,4,5]" :key="i">
                <SkeletonBox
                :height="'500px'"
                width="'100%'"
                />
            </template>
          </template>
          <template v-else>
            <template v-for="(post,i) in overviewTopPostsData" :key="i">
              <TopPostCard :selected-account="selectedAccount" :post="post" :metrics-rows="rows" />
            </template>
          </template>
        </div>
      </template>
    </template>
  </AnalyticsCardWrapper>
</template>
