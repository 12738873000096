<script setup>
import { ref, watch, inject, computed, onMounted } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import CstButton from '@ui/Button/CstButton.vue'
import useIntegrationComposable from '@src/modules/integration/Composables/useIntegrationComposable'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import { useStore } from '@/src/store/base'

// declarations
const root = inject('root')
const { $bvModal } = root
const store = useStore()
const menuItems = ref({ $el: null })
const emit = defineEmits([
  'onDropdownStateChange',
  'onConnect',
  'onConnectCustomApp',
])

const {appData, shouldShowWhiteLabelData} = useWhiteLabelApplication()
const { fetchTwitterCustomApps, twitterCustomApps } = useIntegrationComposable()

// methods
const handleNewAppClick = () => {
  $bvModal.show('add-twitter-account-modal')
}

const handleConnect = () => emit('onConnect')
const handleConnectCustomApp = (_id) => emit('onConnectCustomApp', _id)

// computed
const isTwitterAllowed = computed(
  () => store.getters.getPlan.subscription?.is_twitter_allowed
)

const props = defineProps({
  totalAccounts: {
    type: Number,
    required: false,
  },
})

// hooks
watch(
  () => menuItems.value.$el,
  (n) => emit('onDropdownStateChange', n !== null)
)

onMounted(async () => {
  await fetchTwitterCustomApps()
})
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        as="span"
        class="
          cursor-pointer
          child
          group
        "
      >
        <img src="@assets/img/icons/onboarding/add.svg" alt="add" class="w-5 h-5 mr-2 block group-hover:hidden">
        <p class="text-[0.75rem] xl:text-[0.875rem] !text-[#2A70FC] font-medium hidden group-hover:block mr-2"> Connect </p>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        ref="menuItems"
        class="
          !cursor-default
          absolute
          right-0
          z-10
          mt-2
          w-60
          rounded-md
          bg-white
          shadow-lg
          ring-1
          !ring-[#F2F3F8]
        "
      >
        <div
          v-if="isTwitterAllowed"
          class="py-1 border !border-b-[1px] !border-gray-100 cursor-pointer"
          @click="handleConnect"
        >
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active && 'bg-gray-100',
                'flex gap-2 px-4 py-2 text-[#3C4549] align-items-center',
              ]"
            >
              <img
                :src="shouldShowWhiteLabelData ? appData.brandLogo : '/favicons/apple-touch-icon-57x57.png'"
                class="h-[1.5rem]"
                alt=""
              />
              <p class="font-normal text-xs lg:text-sm">
                Connect with {{appData.businessName}}
              </p>
            </div>
          </MenuItem>
        </div>
        <div
          v-if="twitterCustomApps.length > 0"
          class="py-1 border !border-b-[1px] !border-gray-100 cursor-pointer"
        >
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'flex gap-2 px-4 py-2',
              ]"
            >
              <p class="font-semibold text-xs lg:text-sm"> Custom Apps </p>
            </div>
          </MenuItem>
        </div>
        <div
          v-for="customApp in twitterCustomApps"
          :key="customApp._id"
          class="py-1 border !border-b-[1px] !border-gray-100 cursor-pointer"
          @click="handleConnectCustomApp(customApp._id)"
        >
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'flex gap-2 px-4 py-2',
              ]"
            >
              <p class="font-normal text-xs lg:text-sm">
                {{ customApp.app_name }}
              </p>
            </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem>
            <div class="flex justify-center p-3" @click="handleNewAppClick">
              <CstButton class="w-full" variant="primary">
                <p class="text-[#FFFCFC] font-medium text-sm">
                  Add New Custom App
                </p>
              </CstButton>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
