<script setup>
import { defineProps, computed, ref, defineEmits, onMounted, onBeforeUnmount, onBeforeMount } from 'vue'
import useDateFormat from "@common/composables/useDateFormat";
import ExportButton from '@src/modules/analytics/views/common/ExportButton'
import CstInputFields from '@ui/Input/CstInputFields'
import { EventBus } from '@common/lib/event-bus'

const emits = defineEmits(['dateChange'])

defineProps({
  type: {
    type: String,
    default: 'default',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectedAccounts: {
    type: Array,
    default: () => [],
  },
})
const { momentWrapper } = useDateFormat()
const dateRange = ref('')
const startDate = ref('')
const endDate = ref('')
const topPostsLimit = ref(5)
const isAllAccountsSelected = ref(false)
const labels = ref([])
const campaigns = ref([])
const defaultDateRange = ref([
  momentWrapper().subtract(30, 'days').toDate(),
  momentWrapper().endOf('day').toDate(),
])

const dateOptions = computed(() => ({
  defaultValue: dateRange.value,
  shortcuts: [
    {
      text: 'All Time',
      onClick() {
        const startDate = momentWrapper()
          .subtract(5, 'years')
          .startOf('year')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: '24 hours',
      onClick() {
        const startDate = momentWrapper()
          .subtract(24, 'hours')
          .startOf('hours')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: '48 hours',
      onClick() {
        const startDate = momentWrapper()
          .subtract(48, 'hours')
          .startOf('hours')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: 'Last 3 days',
      onClick() {
        const startDate = momentWrapper()
          .subtract(3, 'days')
          .startOf('day')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: 'Last 7 Days',
      onClick() {
        const startDate = momentWrapper()
          .subtract(7, 'days')
          .startOf('day')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: 'Last 30 Days',
      onClick() {
        const startDate = momentWrapper()
          .subtract(30, 'days')
          .startOf('day')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: 'Last 3 Months',
      onClick() {
        const start = momentWrapper()
          .subtract(90, 'days')
          .startOf('day')
          .toDate()
        const end = momentWrapper().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 6 Months',
      onClick() {
        const start = momentWrapper()
          .subtract(180, 'days')
          .startOf('day')
          .toDate()
        const end = momentWrapper().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 1 Year',
      onClick() {
        const startDate = momentWrapper()
          .subtract(360, 'days')
          .startOf('day')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
    {
      text: 'Last 2 Years',
      onClick() {
        const startDate = momentWrapper()
          .subtract(720, 'days')
          .startOf('day')
          .toDate()
        const endDate = momentWrapper().endOf('day').toDate()
        return [startDate, endDate]
      },
    },
  ],
  disabledDate: (date) => date > new Date(),
  format: 'DD MMM YY',
}))

onBeforeMount(() => {
  EventBus.$on('set-top-posts-limit', (value) => {
  topPostsLimit.value = value
  })
  EventBus.$on('set-report-labels', (value) => {
    console.log(value)
    labels.value = value
  })
  EventBus.$on('set-report-campaigns', (value) => {
    console.log(value)
    campaigns.value = value
  })
  EventBus.$on('set-is-all-accounts-selected', (value) => {
    console.log(value)
    isAllAccountsSelected.value = value
  })

})

onMounted(() => {
  handleDateFilterChange(defaultDateRange.value)
})

onBeforeUnmount(() => {
  EventBus.$off('set-top-posts-limit')
  EventBus.$off('set-report-labels')
  EventBus.$off('set-report-campaigns')
  EventBus.$off('set-is-all-accounts-selected')

})

const handleDateFilterChange = (date) => {
  if (date[0] && date[1]) {
    const start = date[0]
    const end = date[1]
    dateRange.value = `${start} - ${end}`
    startDate.value = start
    endDate.value = end
    emits('dateChange', {
      dateRange: dateRange.value,
      startDate: start,
      endDate: end,
    })
  }
}
</script>

<template>
  <div class="analytics-filter-bar z-[999] !top-0">
    <div class="analytics-filter-bar__left">
      <slot name="left"></slot>
    </div>
    <div class="analytics-filter-bar__right">
      <div class="analytics-filter-bar__right-btns">
        <slot name="right">
          <CstInputFields
            :date-options="dateOptions"
            :value="defaultDateRange"
            class="planner-date-picker cursor-pointer"
            :class="{
              disabled: disabled,
            }"
            placeholder="Select specific date range"
            size="small"
            type="date"
            :date-picker-popup-classes="['planner-date-picker-popup']"
            @change="handleDateFilterChange"
          />
          <ExportButton
            :accounts="selectedAccounts"
            :top-posts-limit="topPostsLimit"
            :labels="labels"
            :campaigns="campaigns"
            :all-accounts-selected="isAllAccountsSelected"
            :start_date="startDate"
            :end_date="endDate"
            :type="type === 'overview' ? 'group' : type"
            :disabled="disabled"
          ></ExportButton>
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.analytics-filter-bar {
  background: #f4f6fa;
  z-index: 999;
  border-bottom: 0 solid #fff;
}

.analytic-accounts-dropdown {
  background-color: #fff;
  border-radius: 8px;
  min-width: 24rem;

  &__btn {
    flex: 0 1 auto !important;
    width: 100%;
  }

  .dropdown-menu {
    width: 100% !important;
    max-height: 28rem !important;
  }

  .dropdown-item-label {
    span {
      max-width: 16rem !important;
    }
  }
}
.mx-input-wrapper:hover {
  .mx-icon-clear {
    display: none !important;
  }
  .mx-icon-calendar {
    display: block !important;
  }
}
</style>
