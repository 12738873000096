import { TEXT_IMAGE_PREVIEW_URL } from '@src/config/api-utils'

export default function useString() {
  function getNumberOfWordsFromString(str, wordsCount = 2) {
    if (countWords(str) > wordsCount) {
      return str.split(' ').slice(0, wordsCount).join(' ') + '...'
    }

    return str.split(' ').slice(0, wordsCount).join(' ')
  }

  function countWords(str) {
    return str.split(' ').length
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  /**
   * @description used throughout the app in case of fallback images, takes the letter and returns a thumbnail image for that.
   */
  function fallbackImagePreview(
    text,
    bgColor = '97d56c',
    textColor = 'green',
    fontSize = 30
  ) {
    return `${TEXT_IMAGE_PREVIEW_URL}/text_preview/?bg_color=%23${bgColor}&text_color=${textColor}&font_size=${fontSize}&text=${text}`
  }

  /**
   * @description Convert a string to a HEX color code
   * @param {String} str
   * @returns {String} HEX color code
   */
  function stringToHexColor(str) {
    // Generate a hash code from the string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash code to a HEX color code with adjusted brightness
    let color = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;

      // Ensure the color is not too light by setting a minimum and maximum value
      const min = 50; // minimum value to avoid colors too light
      const max = 200; // maximum value to avoid colors too dark

      value = Math.max(min, Math.min(max, value));

      color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
  }

  return {
    countWords,
    getNumberOfWordsFromString,
    capitalizeFirstLetter,
    fallbackImagePreview,
    stringToHexColor,
  }
}
