<script setup>
import { computed, watch} from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'

// state
const {
  impressionsData,
  dataZoomOptions,
  lineChartOptions: impressionChartOptions,
  isReportView,
  isLoadingStates,
  routes,
} = useFacebookAnalytics('Impressions')

const isNoData = computed(() => {
  return impressionsData.value?.impressions?.buckets?.length === 0
})

watch(
  () => [impressionsData.value],
  () => {
    const pageOptions = impressionsData.value?.impressions || {}
    // page chart options
    impressionChartOptions.value.series[0].data = pageOptions?.page_impressions || []
    impressionChartOptions.value.xAxis.data = pageOptions?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(() => isLoadingStates.value?.[routes.OVERVIEW_IMPRESSIONS])

</script>

<template>
  <AnalyticsCardWrapper type="impressions-chart-fb" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Impressions by Page
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Reach is the number of people who saw your post. It is a unique metric that measures the number of people who saw your content. It is important to track reach because it helps you understand how many people are seeing your content and how well it is performing.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...impressionChartOptions,
                  ...dataZoomOptions,
                  grid: { ...impressionChartOptions.grid, bottom: 70 },
                }
              : impressionChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
