<script setup>
import { defineProps } from 'vue'
import externalLinkIcon from '@src/assets/img/analytics/external-link.svg'
import moment from 'moment'

defineProps({
  post: {
    type: Object,
    required: true,
  },
  selectedAccount: {
    type: Object,
    required: true,
  },
  metricsRows: {
    type: Array,
    default: () => [
      {
        icon: '',
        iconClass: '',
        title: 'Total Engagement',
        api_key: 'total_engagement',

      }
    ],
  },
})

const getTimeFormatted = (timeString) => {
  return moment(timeString).format('MMMM Do, YYYY hh:mm A')
}

</script>

<template>
<div class="card-top-post rounded-md h-[535px] p-4 flex flex-col gap-4">
  <div id="avatar_and_redirect" class="flex justify-between gap-3">
    <div class="flex gap-3 items-center">
      <img
        :src="selectedAccount?.image"
        alt="Platform Logo"
        class="w-10 h-10 rounded-full"
        @error="
          $event.target.src =
            'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
        "
      />
        <div class="flex flex-col gap-1">
          <p class="font-medium w-52 truncate">{{ selectedAccount?.name}}</p>
          <p class="text-xs text-gray-700">{{getTimeFormatted(post.created_time)}}</p>
        </div>
    </div>
    <div>
      <a :href="post?.permalink" target="_blank">
        <img :src="externalLinkIcon" alt="External Link Icon">
      </a>
    </div>
  </div>
  <div class="flex flex-col gap-4">
    <div id="caption" :class="{ 'h-[156.5px] overflow-hidden': !post?.full_picture }">
      <p class="text-sm min-h-[22.5px]" :class="{ 'line-clamp-7': !post?.full_picture, 'truncate': post?.full_picture }">{{ post?.caption || " "}}</p>
    </div>
    <div v-if="post?.full_picture" id="preview" >
      <img
        class="w-full h-[120px] rounded-[7px] object-cover object-center "
        :src="post?.full_picture"
        alt="preview"
        @error ="(event) => {
          event.target.src =
            require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
        }"
      >
    </div>
  </div>
  <div id="metrics" class="flex flex-col gap-2">
    <template v-for="(row, index) in metricsRows" :key="row.name">
      <div class=" flex items-center gap-2 justify-between py-2 group select-none" :class="{ 'bottom-border-gray': index < (metricsRows.length - 1) }">
        <div class="flex items-center gap-4">
            <img :src="row?.icon" alt="row icon" class="w-5 h-5" :class="row?.iconClass">
            <p class="text-[#676d70] text-sm font-normal leading-[13.30px] transition-all group-hover:font-medium">{{ row.title }}</p>
        </div>
        <p class="text-right text-[#434343] text-sm font-medium leading-[13.30px] capitalize">{{ post?.[row.api_key] ?? 'N/A' }}</p>
      </div>
    </template>
  </div>
</div>
</template>

<style lang="scss">
  .card-top-post{
    border-radius: 8px;
    border: 1px solid #F2F5F8;
    box-shadow: 0px 9px 11px 0px rgba(0, 0, 0, 0.05);
  }
  .bottom-border-gray{
    border-bottom: 1px solid rgba(136, 136, 136, 0.15);
  }
</style>
