<script setup>
import {  ref } from 'vue'
import OnBoarding from '@src/modules/analytics/views/web-analytics/usermaven/components/OnBoarding.vue';
import FilterBar from '@src/modules/analytics/views/web-analytics/usermaven/components/FilterBar.vue';
import UserMavenLogo from '@assets/img/integration/usermaven-logo.svg'
import useUsermavenAnalytics from '@/src/modules/analytics/views/web-analytics/usermaven/composables/useUsermavenAnalytics.js'

const { isConnected,usermavenConnectedURL } = useUsermavenAnalytics()

const isLoading = ref(true)

const onLoad = () => {
  isLoading.value = false
}

</script>

<template>
  <div class="analytics-main-container relative">

    <div>
      <OnBoarding v-if="!isConnected" />
      <FilterBar />
      <div class="usermaven-main-container">
        <div v-if="isLoading" class="flex flex-col justify-center items-center ">
          <img
            class=" animate-pulse scale-125"
            :src="UserMavenLogo"
            alt="UserMaven"
          />
          <clip-loader
            class="spinner"
            :color="'#70CCEB'"
            :size="'28px'"
          ></clip-loader>
        </div>
        <iframe
          v-show="!isLoading"
          class="border-0"
          width="100%"
          height="100%"
          :src="usermavenConnectedURL"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          :onLoad="onLoad"
          title="Usermaven"
        ></iframe>
      </div>

    </div>
  </div>
</template>

<style lang="scss" >
.usermaven-main-container {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
