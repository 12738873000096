<template>
  <div class="h-screen overflow-x-hidden">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[4.6rem] px-40">
        <p class="text-[#3A4557] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'signup' }">Sign Up</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <template v-if="accountExistLoader">
            <beat-loader :color="'#436aff'"></beat-loader>
          </template>
          <form v-else class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-3xl">Finish {{ account.type }} account setup</h2>
              <p class="text-md text-[#757A8A] mt-5">You're almost there!</p>
            </div>

            <div class="flex flex-col gap-2 pt-12">
              <CstFloatingLabelInput
                  id="email"
                  v-model="account.email"
                  type="email"
                  label="Your email address"
                  class="!h-[3.3rem] mb-2.5"
                  show-icon-left
                  value="email"
                  :disabled="emailDisabled"
              >
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <div>
                <CstFloatingLabelInput
                    id="password"
                    v-model="account.password"
                    class="!h-[3.3rem] mb-2.5"
                    type="password"
                    label="Password"
                    :maxlength="18"
                    show-icon-left
                    value="account.password">
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                </CstFloatingLabelInput>

                <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                </div>
              </div>

              <!-- Business Name -->
              <CstFloatingLabelInput
                  id="bName"
                  v-model="account.business_name"
                  class="!h-[3.3rem]"
                  type="text"
                  label="Workspace Name"
                  show-icon-left
                  value="account.business_name">
                <template v-slot:icon>
                  <i class="far fa-briefcase"></i>
                </template>
              </CstFloatingLabelInput>
              <!-- Google Recaptcha-->
              <vueRecaptcha
                  ref="recaptcha"
                  :sitekey="getGoogleRecaptchaKey"
                  size="invisible"
                  theme="light"
                  loading-timeout="30000"
                  @verify="createAccount"
                  @expire="onCaptchaExpired"
                  @fail="onCaptchaExpired"
                  @error="onCaptchaExpired">
              </vueRecaptcha>
            </div>
            <!-- Terms & Conditions -->
            <div class="pt-5 pb-7">
              <p class="text-[#3A4557]">
                By signing up, you agree to our
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/terms-and-conditions"
                    target="_blank">terms of service</a>
                and
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/privacy-policy"
                    target="_blank"
                >privacy policy.</a
                >
              </p>
            </div>
            <div class="auth-form__button-field">
              <button
                  data-cy="continue_button"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="executeRecaptcha"
              >
                <span class="w-full">Continue <img
                    v-if="registerLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../../assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {workspaceTypes} from '@src/modules/setting/store/states/mutation-types'
import {authenticationTypes} from '@state/mutation-types'
import {accountExistURL, registerURL} from '@src/config/api-utils.js'
import vueRecaptcha from "vue3-recaptcha2";
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import useTime from "@/src/modules/common/composables/useTime"
import useIp from "@/src/modules/common/composables/useIp"
const SignupSideComponent = () => import("@src/components/authentication/SignupSideComponent.vue")
export default {
  components: {
    SignupSideComponent,
    vueRecaptcha,
    CstFloatingLabelInput
  },
  setup() {
    const { getClientTimeZone } = useTime()
    const { ipv4 } = useIp()

    return {
      getClientTimeZone,
      ipv4
    }
  },
  data() {
    return {
      emailDisabled: false,
      account: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        plan: 'trial',
        business_type: '',
        picture: '',
        id: '',
        type: '',
        timezone: this.getClientTimeZone(),
      },
      size: '14px',
      loaderColor: '#fff',
      registerLoader: false,

      email: '',
      password: '',
      cpassword: '',
      accountExistLoader: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  computed: {
    ...mapGetters([]),

    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is okay';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  mounted() {},
  created() {
    this.accountExists()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    ...mapActions(['fetchProfile']),

    async accountExists() {
      this.accountExistLoader = true
      const res = await this.$http
        .post(accountExistURL, {
          id: this.$route.query.id,
          type: this.$route.query.type,
          email: this.$route.query.email,
        })
        .then(
          async (response) => {
            if (response.data.status) {
              // login to the account and redirect
              if (
                response.data.user &&
                response.data.user.status &&
                response.data.user.status === 'invited'
              ) {
                this.alertMessage(response.data.message, 'error')
                this.$router.push({ name: 'login' })
                return false
              }

              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                    redirected_url: this.$route.fullPath,
                  },
                })
                return
              }

              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              if (this.getProfile.onBoarding) {
                this.isUserOnboarded()
              } else {
                if (response.data.user) {
                  this.SET_PROFILE(response.data.user)
                }
                this.loginLoader = false
              }
              return response
            } else {
              if (this.$route.query.email) {
                this.emailDisabled = true
              }
              this.account.type = this.$route.query.type
              this.account.id = this.$route.query.id
              this.account.email = this.$route.query.email
              this.account.firstname = this.$route.query.first_name
              this.account.lastname = this.$route.query.last_name
              this.account.picture = this.$route.query.picture
              this.accountExistLoader = false
            }
            return response
          },
          (response) => {
            return null
            // this.accountExistLoader = false
          }
        )
      console.log('accountExists res > ', res)
      if (res && res.data.status) {
        if (
          res.data.user.state &&
          (res.data.user.state === 'canceled' ||
            res.data.user.state === 'cancelled')
        ) {
          this.$router.push({ name: 'subscription_cancelled' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'deleted') {
          this.$router.push({ name: 'subscription_deleted' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'paused') {
          this.$router.push({ name: 'subscription_paused' })
          return
        }

        if (res.data.user.trial_finished) {
          this.$router.push({ name: 'trial_expired' })
          return
        }
        try {
          await this.fetchWorkspaces()
          this.Plan()
          if (res.data.activeWorkspace) {
            this.$store.commit(
                workspaceTypes.SET_ACTIVE_WORKSPACE,
                res.data.activeWorkspace.workspace
            )
            this.resetDefaultStates()
            this.initializeSection()
            // await this.fetchDiscover()
            this.fetchSocialAccounts()
            this.$router.push({
              name: 'dashboard',
              params: {workspace: res.data.activeWorkspace.workspace.slug},
            })
          } else {
            console.debug('Login: Redirecting to workspaces')
            this.$router.push({name: 'workspaces'})
          }
        } catch (e) {
          console.error('Login: Error while fetching workspaces', e)
          this.$router.push({name: 'workspaces'})
        }
      }
    },
    executeRecaptcha() {
      const validate = this.validateRegisterForm()
      if(validate) this.$refs.recaptcha.execute()
    },
    onCaptchaExpired() {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    async createAccount(recaptchaToken) {
      this.registerLoader = true
      this.account.captcha_code = recaptchaToken
      this.$refs.recaptcha.reset()
      const response = await this.$http
          .post(registerURL, {...this.account, ip: this.ipv4})
          .then((response) => {
            this.registerLoader = false
            if (response.data.status) {
              this.$store.commit(
                  authenticationTypes.SET_JWT_TOKEN,
                  response.data.token
              )
              this.$store.commit(
                  authenticationTypes.SET_LOGGED_USER,
                  response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

            } else {
              this.alertMessage(response.data.message, 'error')
            }
            return response
          })
        .catch((response) => {})
      if (response.data.status) {
        await this.fetchProfile()
        // await this.identify()
        await this.Plan()

        await this.trackSignUpEvent()
        // this.$store.dispatch('trackEvent', { event: 'signed_up' })

        if (response.data.user && response.data.user.email_verify === false) {
          this.$router.push({ name: 'email_verification' })
        } else {
          // this.fetchWorkspaces()
          this.$router.push({ name: 'onboardingWorkspace' })
        }
      }
    },
    /**
     * Validate the register form
     */
    validateRegisterForm() {
      const regexp = /^[\p{L} .0-9]+$/u

      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return false
      }

      if (this.password_state === 'danger') {
        this.alertMessage(this.password_strength_message, 'error')
        return false
      }

      if (!this.account.business_name) {
        this.alertMessage('Please enter a workspace name', 'error')
        return false
      }

      if (this.account.business_name && this.account.business_name.length < 4) {
        this.alertMessage('The workspace name must be at least 4 characters.', 'error')
        return false
      }

      if (!regexp.test(this.account.business_name)) {
        this.alertMessage('Please Enter a Valid Workspace name, (may include letters and numbers)', 'error')
        return false
      }

      return true
    }
  },
}
</script>
