import {computed, ref} from 'vue'
import {useRouter} from 'vue-router'
import proxy from '@common/lib/http-common'
import {fetchWhiteLabelDomainData} from '@src/modules/setting/config/api-utils'
import {useStore} from '@state/base'

const appData = ref({
    businessName: '',
    brandLogo: '',
    favicon: '',
    websiteURL: '',
    termsAndConditions: '',
    privacyPolicy: '',
})

export default function useWhiteLabelApplication() {
    const router = useRouter()
    const {getters} = useStore()

    const isWhiteLabelDomain = computed(() => {
        return (
            !window.location.hostname.includes('.contentstudio.io') &&
            !window.location.hostname.includes('localhost')
        )
    })
    const isWhiteLabeledWorkspace = computed(
        () => !!getters.getActiveWorkspace.whitelabel?._id
    )
    const shouldShowWhiteLabelData = computed(
        () => isWhiteLabelDomain.value && isWhiteLabeledWorkspace.value
    )

    const updateRouteMetaTitles = () => {
        const routeSubtitle = isWhiteLabelDomain.value ? appData.value?.businessName || 'App' : "ContentStudio"
        router.getRoutes().forEach((route) => {
            if (route.meta?.title) {
                route.meta.originalTitle = route.meta.originalTitle || route.meta.title
                route.meta.title = route.meta.originalTitle.replace(
                    'ContentStudio',
                    routeSubtitle
                )
            }
        })
    }

    const setLocalWhiteLabelSettings = (settings) => {
        localStorage.setItem('whiteLabelSettings', JSON.stringify(settings))
    }

    const fetchWhiteLabelSettings = async () => {
        if (!isWhiteLabelDomain.value) {
            return
        }

        try {
            const {data} = await proxy.get(fetchWhiteLabelDomainData, {
                params: {
                    domain: window.location.hostname,
                },
            })
            if (data.status) {
                appData.value = transformWhiteLabelSettings(data.whitelabel)
                applyWhiteLabelSettings()
                setLocalWhiteLabelSettings(appData.value)
            }
        } catch (error) {
            console.error('Error fetching white label settings:', error)
        }
    }

    const updateAllIcons = (faviconUrl) => {
        console.log("Updating all icons with:", faviconUrl);

        // Add a timestamp to prevent caching
        const url = `${faviconUrl}?v=${new Date().getTime()}`;

        // Update all favicon links
        document.querySelectorAll("link[rel='icon'], link[rel='shortcut icon']").forEach(link => {
            link.href = url;
            console.log(`Updated favicon: ${link.sizes.value || 'default'}`);
        });

        // Update all Apple Touch Icons
        document.querySelectorAll("link[rel='apple-touch-icon-precomposed'], link[rel='apple-touch-icon']").forEach(link => {
            link.href = url;
            console.log(`Updated Apple Touch Icon: ${link.sizes.value || 'default'}`);
        });

        // Create a new icon if none exist
        if (!document.querySelector("link[rel='icon']")) {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = url;
            document.head.appendChild(newLink);
            console.log("Created new favicon link");
        }

        // Force favicon refresh for some browsers
        const newFaviconLink = document.createElement('link');
        newFaviconLink.rel = 'shortcut icon';
        newFaviconLink.href = url;
        document.head.appendChild(newFaviconLink);
        console.log("Added forced favicon refresh link");
    }

    const updateAllIconsToDefault = () => {
        const defaultIconPath = '/favicons/';
        const iconSizes = ['16x16', '32x32', '96x96', '128x128', '196x196'];
        const appleIconSizes = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '144x144', '152x152'];

        // Update standard favicons
        iconSizes.forEach(size => {
            const link = document.querySelector(`link[rel='icon'][sizes='${size}']`);
            if (link) {
                link.href = `${defaultIconPath}favicon-${size}.png`;
                console.log(`Reset favicon ${size} to default`);
            }
        });

        // Update shortcut icon
        const shortcutIcon = document.querySelector("link[rel='shortcut icon']");
        if (shortcutIcon) {
            shortcutIcon.href = `${defaultIconPath}favicon.ico`;
            console.log("Reset shortcut icon to default");
        }

        // Update Apple Touch Icons
        appleIconSizes.forEach(size => {
            const link = document.querySelector(`link[rel='apple-touch-icon-precomposed'][sizes='${size}']`);
            if (link) {
                link.href = `${defaultIconPath}apple-touch-icon-${size}.png`;
                console.log(`Reset Apple Touch Icon ${size} to default`);
            }
        });

        // Update main favicon
        const mainFavicon = document.getElementById('favicon');
        if (mainFavicon) {
            mainFavicon.href = `${defaultIconPath}favicon.ico`;
            console.log("Reset main favicon to default");
        }
    };


    const transformWhiteLabelSettings = (settings = {}) => {
        return {
            id: settings._id,
            businessName: settings.business_name,
            brandLogo: settings.logo,
            favicon: settings.favicon,
            websiteURL: settings.website_url,
            termsAndConditions: settings.terms_of_service,
            privacyPolicy: settings.privacy_policy,
        }
    }

    const resetAppData = () => {
        appData.value = {
            businessName: 'ContentStudio',
            brandLogo: '/favicons/favicon.ico',
            favicon: '/favicons/favicon.ico',
            websiteURL: '',
            termsAndConditions: '',
            privacyPolicy: '',
        }
    }

    const applyWhiteLabelSettings = () => {
        updateRouteMetaTitles()
        updateAllIcons(appData.value.favicon)
    }

    const applyDefaultSettings = () => {
        updateAllIconsToDefault()

        router.getRoutes().forEach((route) => {
            if (route.meta?.originalTitle) {
                route.meta.title = route.meta.originalTitle
            }
        })

        resetAppData()
    }

    return {
        isWhiteLabeledWorkspace,
        appData,
        isWhiteLabelDomain,
        shouldShowWhiteLabelData,
        transformWhiteLabelSettings,
        fetchWhiteLabelSettings,
        applyWhiteLabelSettings,
        applyDefaultSettings,
        updateRouteMetaTitles,
    }
}
