<template>
  <div
    class="pl-2 py-6 !border-l-0 !border-t-0 !border-b-0 border !border-[#EAF0F6] relative"
  >
    <div>
      <div class="text-base mb-6 px-0.5 font-weight-500">
        Social Analytics
      </div>

      <nav aria-label="Navigation">
        <template
          v-for="platform in socialAnalyticsRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt="Icon"
                />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

      <template v-if="isCompetitorAnalyticsEnabled">
        <div class="mb-6 mt-7 flex items-center gap-1 relative">
          <span class="text-base font-weight-500">Competitor Analytics</span>
        </div>
        <nav aria-label="Navigation">
          <template
            v-for="platform in competitorAnalyticsRoutes"
            :key="`platform_${platform.title}`"
          >
            <router-link v-if="platform.show" :to="platform.getLink">
              <div
                class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
                :class="getOptionClass(platform.routeName)"
              >
                <div
                  class="h-8 w-8 mr-4 flex items-center justify-center"
                  :class="platform.iconWrapperClass"
                  v-html="platform.icon"
                >
                </div>
                <span class="group-hover:text-black-900">
                  {{ platform.title }}
                </span>
                <span
                  v-if="platform.isNew"
                  class="px-1.5 py-0.5 absolute right-2 select-none"
                >
                  <img
                    src="@src/assets/img/common/new_tag.svg"
                    alt="Icon"
                  />
                </span>
              </div>
            </router-link>
          </template>
        </nav>
      </template>
      <template v-else>
        <router-link :to="{ name: 'competitor_analytics' }">
          <div
            class="hover:bg-[#F2F4F6] mt-7 py-1 rounded-sm"
            :class="
              $route.name.includes('competitor_analytics') &&
              'bg-gray-100 font-medium text-[#000D21]'
            "
          >
            <div class="text-gray-900 mb-6 flex items-center gap-2 relative">
              <span class="text-base font-weight-500"
                >Competitor Analytics</span
              >
              <img
                v-if="!isCompetitorAnalyticsEnabled"
                src="@assets/img/icons/lock_black.svg"
                class="w-4"
                alt=""
              />
            </div>
            <div class="text-[#69788E]">
              <div class="flex items-center mb-1.5 py-0.5 px-2">
                <i
                  class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"
                ></i>
                <span> Facebook </span>
              </div>
              <div
                class="flex items-center mb-1.5 justify-between rounded-sm py-0.5 px-2"
              >
                <span class="flex justify-center items-center">
                  <i
                    class="h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"
                  ></i>
                  <span class="">Instagram</span>
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </template>

      <div class="text-base mb-6 mt-7 font-weight-500">
        Performance Analytics
        <span
          class="px-1.5 absolute right-2 select-none"
        >
          <img
            src="@src/assets/img/common/new_tag.svg"
            alt="Icon"
          />
        </span>
      </div>

      <nav aria-label="Navigation">
        <template
          v-for="platform in performanceReportRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt="Icon"
                />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

      <div class="text-base mb-6 mt-7 font-weight-500">
        Web Analytics
        <span
          class="px-1.5 absolute right-2 select-none"
        >
          <img
            src="@src/assets/img/common/new_tag.svg"
            alt="New Icon image"
          />
        </span>
      </div>

      <nav aria-label="Navigation">
        <template
          v-for="platform in webAnalyticsRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt="Icon"
                />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

      <div class="text-base mb-6 mt-7 font-weight-500"> Manage Reports</div>

      <nav class="">
        <template
          v-for="platform in manageReportRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt="New Icon image"
                />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

    </div>
  </div>
</template>

<script setup>
// constants
import useAnalyticsRoutes from '@src/modules/analytics/components/common/composables/useAnalyticsRoutes'
import { useRoute } from 'vue-router'

defineProps({
  isCompetitorAnalyticsEnabled: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()

const {
  socialAnalyticsRoutes,
  competitorAnalyticsRoutes,
  manageReportRoutes,
  performanceReportRoutes,
  webAnalyticsRoutes,
} = useAnalyticsRoutes()

const getOptionClass = (routeName) => {
  return route?.name?.includes(routeName)
    ? 'bg-gray-100 !font-medium !text-[#000D21]'
    : 'text-[#69788E]'
}
</script>
