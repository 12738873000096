import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import useDateFormat from "@common/composables/useDateFormat";
import FollowersGrowthIcon from "@/src/assets/img/icons/analytic/colored/followers-growth-icon.svg";
import EngagementPageIcon from "@/src/assets/img/icons/analytic/colored/engagement-page-icon.svg";
import EngagementsColorIcon from "@/src/assets/img/icons/analytic/colored/engagements-color-icon.svg";
import FeedbackIcon from "@/src/assets/img/icons/analytic/colored/feedback-icon.svg";
import ImpressionPageIcon from "@/src/assets/img/icons/analytic/colored/impression-page-icon.svg";
import ImpressionsPostIcon from "@/src/assets/img/icons/analytic/colored/impressions-post-icon.svg";
import PostClickIcon from "@/src/assets/img/icons/analytic/colored/post-click-icon.svg";
import PostIcon from "@/src/assets/img/icons/analytic/colored/post-icon.svg";
import ReachIcon from "@/src/assets/img/icons/analytic/colored/reach-icon.svg";
import ReactionsIcon from "@/src/assets/img/icons/analytic/colored/reactions-icon.svg";
import RepostIcon from "@/src/assets/img/icons/analytic/colored/repost-icon.svg";
import SentimentsIcon from "@/src/assets/img/icons/analytic/colored/sentiments-icon.svg";
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

import EngagementIcon from "@/src/assets/img/icons/analytic/plain/total-engagement.svg";
import ReactionsPlainIcon from "@/src/assets/img/icons/analytic/plain/reactions.svg";
import CommentsIcon from "@/src/assets/img/icons/analytic/plain/comments-icon.svg";
import SharesIcon from "@/src/assets/img/icons/analytic/plain/shares.svg";
import ImpressionsIcon from "@/src/assets/img/icons/analytic/plain/post-impressions.svg";
import PostClickPlainIcon from "@/src/assets/img/icons/analytic/plain/post-clicks.svg";
import PostTypeIcon from "@/src/assets/img/icons/analytic/plain/post-type.svg";
import AngryIcon from "@/src/assets/img/icons/analytic/plain/angry.svg";
import LikeIcon from "@/src/assets/img/icons/analytic/plain/likes.svg";
import HahaIcon from "@/src/assets/img/icons/analytic/plain/haha.svg";
import HeartIcon from "@/src/assets/img/icons/analytic/plain/heart.svg";
import SadIcon from "@/src/assets/img/icons/analytic/plain/sad.svg";
import WowIcon from "@/src/assets/img/icons/analytic/plain/wow.svg";
import ReachPlainIcon from "@/src/assets/img/icons/analytic/plain/reach.svg";

import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
const { momentWrapper } = useDateFormat()


const { camelToSnake } = useComposerHelper()

const { getAccountId } = useAnalyticsUtils()
const { formatNumber } = useNumber()
const {
  ANALYTICS_DELIMITER,
} = useAnalytics()

const ongoingRequests = new Map();

const selectedAccount = ref(null)
const postsLimit = ref(10)

const isLoadingStates = ref({
  summary: false,
  overviewAudienceGrowth: false,
  overviewReach: false,
  overviewActiveUsers: false,
  overviewImpressions: false,
  overviewEngagement: false,
  overviewTopPosts: false,
  overviewAudienceLocation: false,
  overviewDemographics: false,
  overviewReelsAnalytics: false,
  overviewPublishingBehaviour: false,
  overviewVideoInsights: false,
  getTopPosts: false,
})

const TopLeastEngagementDropdown = [
  { label: 'Impressions', api_key: 'impressions' },
  { label: 'Engagements', api_key: 'engagement_rate' },
]
const selectedTopLeastSortType = ref({
  top: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
  least: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
})

const mediaTypeMap = {
  images: 'Image',
  videos: 'Video',
  reels: 'Reel',
}

const routes = {
  SUMMARY: 'summary',
  OVERVIEW_AUDIENCE_GROWTH: 'overviewAudienceGrowth',
  OVERVIEW_REACH: 'overviewReach',
  OVERVIEW_ACTIVE_USERS: 'overviewActiveUsers',
  OVERVIEW_IMPRESSIONS: 'overviewImpressions',
  OVERVIEW_ENGAGEMENT: 'overviewEngagement',
  OVERVIEW_TOP_POSTS: 'overviewTopPosts',
  OVERVIEW_REELS_ANALYTICS:'overviewReelsAnalytics',
  OVERVIEW_AUDIENCE_LOCATION: 'overviewAudienceLocation',
  OVERVIEW_DEMOGRAPHICS: 'overviewDemographics',
  OVERVIEW_VIDEO_INSIGHTS: 'overviewVideoInsights',
  OVERVIEW_PUBLISHING_BEHAVIOUR: 'overviewPublishingBehaviour',
  TOP_POSTS: 'getTopPosts',
}

const rollupTypes = {
  AUDIENCE: 'audience',
  REACH: 'reach',
  IMPRESSIONS: 'impressions',
  ENGAGEMENTS: 'engagements',
  REELS: 'reels',
  VIDEO_INSIGHTS: 'Video Insights',
}

// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)

const cardsData = ref({})
const audienceGrowthData = ref({})
const reachData = ref({})
const activeUsersData = ref({})
const impressionsData = ref({})
const engagementsData = ref({})
const overviewTopPostsData = ref({})
const overviewAudienceLocationData = ref({})
const overviewDemographicsData = ref({})
const overviewReelsAnalyticsData = ref({})
const overviewVideoInsightsData = ref({})
const overviewPublishingBehaviourData = ref({})
const topPostsData = ref([])

const cards = ref([
  {
    title: 'Followers',
    key: 'page_follows',
    tooltip: `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`,
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Total Posts',
    key: 'doc_count',
    tooltip: `The total number of impressions received by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Impressions are based on the lifetime performance of the posts.`,
    icon: PostIcon,
  },
  {
    title: 'Impressions by Page',
    key: 'page_impressions',
    tooltip:
      'The total number of engagement actions generated by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.',
    icon: ImpressionPageIcon,
  },
  {
    title: 'Impressions by Posts',
    key: 'impressions',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: ImpressionsPostIcon,
  },
  {
    title: 'Reach',
    key: 'reach',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: ReachIcon,
  },
  {
    title: 'Engagements by Page',
    key: 'page_engagements',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: EngagementPageIcon,
  },
  {
    title: 'Engagement by Posts',
    key: 'total_engagement',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: EngagementsColorIcon,
  },
  {
    title: 'Post Clicks',
    key: 'posts_clicks',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: PostClickIcon,
  },
  {
    title: 'Reactions',
    key: 'reactions',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: ReactionsIcon,
  },
  {
    title: 'Shares',
    key: 'repost',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: RepostIcon,
  },
  {
    title: 'Sentiments',
    key: 'total_engagement_rate_per_impression',
    polarityKeys: {
      positive: 'positive_sentiment',
      negative: 'negative_sentiment',
    },
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: SentimentsIcon,
  },
  {
    title: 'Feedback',
    key: 'total_engagement_rate_per_impression',
    polarityKeys: {
      positive: 'page_positive_feedback',
      negative: 'page_negative_feedback',
    },
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
    icon: FeedbackIcon,
  },
])

const audienceRollupKeys = ref([
  {
    title: 'Page Likes',
    key: 'fan_count',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Avg. Page Fans by Like',
    key: 'avg_page_fans_by_like',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Avg. Page Fans by Unlike',
    key: 'avg_page_fans_by_unlike',
    tooltip:
      'The total number of times users have clicked on your pins published during the selected time period.',
  },
  {
    title: 'Talking about Page',
    key: 'talking_about_count',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
])

const reachRollupKeys = ref([
  {
    title: 'Posts Reach',
    key: 'total_reach',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Avg. Reach',
    key: 'avg_reach',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Organic Post Reach',
    key: 'post_reach_organic',
    tooltip:
      'The total number of times users have clicked on your pins published during the selected time period.',
  },
  {
    title: 'Viral Post Reach',
    key: 'post_reach_viral',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Paid Post Reach',
    key: 'post_reach_paid',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
])

const impressionsRollupKeys = ref([
  {
    title: 'Page Impressions',
    key: 'total_impressions',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Avg. Impressions / day',
    key: 'avg_impressions_per_day',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Avg. Impressions / week',
    key: 'avg_impressions_per_week',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  }
])

const engagementsRollupKeys = ref([
  {
    title: 'Page Engagements',
    key: 'page_engagements',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Avg. Engagements / day',
    key: 'avg_engagements_per_day',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Avg. Engagements / week',
    key: 'avg_engagements_per_week',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
])

const reelsRollupKeys = ref([
  {
    title: 'Reels',
    key: 'total_reels',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Watch Time (Sec)',
    key: 'total_seconds_watched',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Avg. Time Watched',
    key: 'average_seconds_watched',
    tooltip:
      'The total number of times users have clicked on your pins published during the selected time period.',
  },
  {
    title: 'Initial Plays',
    key: 'initial_plays',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Reach',
    key: 'reach',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Reactions',
    key: 'reactions',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Comments',
    key: 'comments',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Shares',
    key: 'shares',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
])
const videoInsightsRollupKeys = ref([
  {
    title: 'Videos',
    key: 'total_posts',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Total Watch Time (Sec)',
    key: 'total_view_time',
    tooltip:
      'The total number of times users have clicked on your pins published during the selected time period.',
  },
  {
    title: 'Paid Watch Time (Sec)',
    key: 'paid_view_time',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Organic Watch Time (Sec)',
    key: 'organic_view_time',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Total Views',
    key: 'total_views',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Paid Views',
    key: 'paid_views',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Organic Views',
    key: 'organic_views',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Reactions',
    key: 'reactions',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Comments',
    key: 'comments',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Shares',
    key: 'shares',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const calculateGrowthAndDiff = (current, previous) => {
  if (!current || !previous) {
    return { growth: 0, difference: 0 };
  }
  const difference = (current - previous) || 0;
  const divisor = previous > 0 ? previous : 1;
  const growth= difference === 0 ? 0 : formatNumber(((difference / divisor) * 100));
  return { growth , difference};
}
const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: undefined,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, previous } = cardsData.value
  const { growth, difference } = calculateGrowthAndDiff(current?.[card.key], previous?.[card.key])

  let dataObject = {}
  if (card.polarityKeys) {
    const positive = current?.[card.polarityKeys.positive] || 0
    const negative = current?.[card.polarityKeys.negative] || 0
    const previousPositive = previous?.[card.polarityKeys.positive] || 0
    const previousNegative = previous?.[card.polarityKeys.negative] || 0

    const total = positive + negative;
    const previousTotal = previousPositive + previousNegative;
    const { growth, difference } = calculateGrowthAndDiff(total, previousTotal)

    dataObject = {
      showPositiveNegative: true,
      positive: formatNumber(positive),
      negative: formatNumber(negative),
      total: formatNumber(total),
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: total || defaultValue.originalValue,
    }
  }
  else{
    dataObject = {
      total: formatNumber(+current?.[card.key]) || defaultValue.total,
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: current?.[card.key] || defaultValue.originalValue,
    }
  }

  return {
    title: card.title,
    tooltip: card.tooltip,
    icon: card.icon,
    ...dataObject,
  }
}
const getRollupData = (dataSource, card, key) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: '',
  }

  const rollupData = dataSource.value?.[key]
  if (!rollupData) {
    return defaultValue
  }

  const { current = {}, previous = {} } = rollupData

  const { growth, difference } = calculateGrowthAndDiff(current?.[card.key], previous?.[card.key])

  return {
    title: card.title,
    total: formatNumber(current?.[card.key]) || defaultValue.total,
    growth: growth || defaultValue.growth,
    difference: difference || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card?.tooltip || defaultValue.tooltip,
  }
}
const validPostsTableHeaders = [
  'thumbnail',
  'mediaType',
  'totalEngagement',
  'postImpressions',
  'postImpressionsUnique',
  'postClicks',
  'total',
  'like',
  'love',
  'haha',
  'wow',
  'sad',
  'angry',
  'comments',
  'shares',
]
const validPostsTableHeadersReport = [
  'thumbnail',
  'mediaType',
  'totalEngagement',
  'postImpressions',
  'postImpressionsUnique',
  'postClicks',
  'total',
  'comments',
  'shares',
]

const nonSortableItems = ['thumbnail', 'description', 'pageName', 'mediaType']

// object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  pageName: 'Page Name',
  mediaType: 'Media Type',
  totalEngagement: 'Engagements',
  postImpressions: 'Impressions',
  postImpressionsUnique: 'Reach',
  postClicks: 'Post Clicks',
  total: 'Reactions',
  like: 'Likes',
  love: 'Love',
  haha: 'Haha',
  wow: 'Wow',
  sad: 'Sad',
  angry: 'Angry',
  comments: 'Comments',
  shares: 'Shares',
}

const headerTooltips = {
  engagementRate:
    "The engagement rate of your pin published during the selected time period, based on the pin's lifetime performance.",
  engagement:
    "The total number of engagement actions (pin clicks, outbound clicks, saves) received on your pin published during the selected time period, based on the pin's lifetime performance.",
  impressions:
    "The total number of times your pin published during the selected time period has been displayed on users' screens, based on the pin's lifetime performance.",
  clicks:
    "The total number of times users have clicked on your pin published during the selected time period, based on the pin's lifetime performance.",
  outboundClicks:
    'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  saves:
    "The total number of times users have pinned or saved your pin published during the selected time period, based on the pin's lifetime performance.",
}

// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  thumbnail: (value) => value,
  pageName: (value) => value,
  mediaType: (value) => value,
  totalEngagement: (value) => formatNumber(value),
  postImpressions: (value) => formatNumber(value),
  total: (value) => formatNumber(value),
  like: (value) => formatNumber(value),
  love: (value) => formatNumber(value),
  haha: (value) => formatNumber(value),
  wow: (value) => formatNumber(value),
  sad: (value) => formatNumber(value),
  angry: (value) => formatNumber(value),
  comments: (value) => formatNumber(value),
  shares: (value) => formatNumber(value),
  description: (value) => value,
  postImpressionsUnique: (value) => formatNumber(value),
  postClicks: (value) => formatNumber(value),
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

const postModalFields = [
  {
    label: 'Impressions',
    key: 'postImpressions',
    iconSrc: ImpressionsIcon,
    iconClass:'',
    tooltip: `The total number of times your pin has been displayed on users' screens during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Engagements',
    key: 'totalEngagement',
    iconSrc: EngagementIcon,
    iconClass:'scale-[1.2]',
    tooltip: `The total number of engagement actions (pin clicks, outbound clicks, saves) received during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Post Clicks',
    key: 'postClicks',
    iconClass:'',
    iconSrc: PostClickPlainIcon,
    tooltip: `The total number of times users have clicked on your pin during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Reach',
    key: 'postImpressionsUnique',
    iconSrc: ReachPlainIcon,
    iconClass:'scale-[1.2]',
    tooltip: `The total number of times users have clicked on your pin during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Reactions',
    key: 'total',
    iconSrc: ReactionsPlainIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Likes',
    key: 'like',
    iconSrc: LikeIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Love',
    key: 'love',
    iconSrc: HeartIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Haha',
    key: 'haha',
    iconSrc: HahaIcon,
    iconClass:'scale-[1.3]',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Wow',
    key: 'wow',
    iconSrc: WowIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Sad',
    key: 'sad',
    iconSrc: SadIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Angry',
    key: 'angry',
    iconSrc: AngryIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Comments',
    key: 'comments',
    iconSrc: CommentsIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Shares',
    key: 'shares',
    iconSrc: SharesIcon,
    iconClass:'',
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: PostTypeIcon,
    iconClass:'',
    tooltip: `Media type of the post.`,
  },
]
const getRollup = (type) => {
  switch (type) {
    case rollupTypes.AUDIENCE:
      return {
        keys: audienceRollupKeys.value,
        getData: (card) => getRollupData(audienceGrowthData, card, 'audience_growth_rollup')
      }
    case rollupTypes.REACH:
      return {
        keys: reachRollupKeys.value,
        getData: (card) => getRollupData(reachData, card, 'reach_rollup'),
      }
    case rollupTypes.IMPRESSIONS:
      return {
        keys: impressionsRollupKeys.value,
        getData: (card) => getRollupData(impressionsData, card, 'impressions_rollup'),
      }
    case rollupTypes.ENGAGEMENTS:
      return {
        keys: engagementsRollupKeys.value,
        getData: (card) => getRollupData(engagementsData, card, 'engagement_rollup'),
      }
    case rollupTypes.REELS:
      return {
        keys: reelsRollupKeys.value,
        getData: (card) => getRollupData(overviewReelsAnalyticsData, card, 'reels_rollup'),
      }
    case rollupTypes.VIDEO_INSIGHTS:
      return {
        keys: videoInsightsRollupKeys.value,
        getData: (card) =>  getRollupData(overviewVideoInsightsData, card, 'video_rollup'),
      }
    default:
      return {
        keys: [],
        getData: () => {},
      }
  }
}
const getHeaderApiKey = (header) => {
  if(tableSortableItems.value.includes(header)){
    return camelToSnake(header)
  }
  return '';
}

const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

const fanCount = computed(() => {
  return cardsData.value?.current?.fan_count || 0
})

const tableSortableItems = computed(() => {
  return validPostsTableHeaders.filter((header) => !nonSortableItems.includes(header))
})

const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

const transformObject = (data) => {
  return data.map((post) => {
    return {
      id: post.post_id,
      pageName: post.page_name,
      pageId: post.page_id,
      permalink: post.permalink,
      statusType: post.status_type,
      mediaType: mediaTypeMap[post.media_type] || post.media_type,
      videoId: post.video_id,
      category: post.category,
      publishedBy: post.published_by,
      publishedByUrl: post.published_by_url,
      sharedFromName: post.shared_from_name,
      sharedFromId: post.shared_from_id,
      sharedFromLink: post.shared_from_link,
      like: +post.like,
      love: +post.love,
      haha: +post.haha,
      wow: +post.wow,
      sad: +post.sad,
      angry: +post.angry,
      total: +post.total,
      shares: +post.shares,
      comments: +post.comments,
      postClicks: +post.post_clicks,
      totalEngagement: +post.total_engagement,
      postEngagedUsers: +post.post_engaged_users,
      dayOfWeek: post.day_of_week,
      hourOfDay: post.hour_of_day,
      createdAt: post.created_time,
      updatedAt: post.updated_time,
      savingTime: post.saving_time,
      messageTags: post.message_tags,
      postMetadata: post.post_metadata,
      description: post.caption,
      postDescription: post.description,
      thumbnail: post.full_picture,
      postImpressions: +post.post_impressions,
      postImpressionsUnique: +post.post_impressions_unique,
      postImpressionsPaid: +post.post_impressions_paid,
      postImpressionsPaidUnique: +post.post_impressions_paid_unique,
      postImpressionsOrganic: +post.post_impressions_organic,
      postImpressionsOrganicUnique: +post.post_impressions_organic_unique,
      postImpressionsViral: +post.post_impressions_viral,
      postImpressionsViralUnique: +post.post_impressions_viral_unique,
      postVideoViews: +post.post_video_views,
      totalImpressions: +post.total_impressions,
      mediaAssets: post.media_assets.map(asset => ({
        mediaId: asset.media_id,
        caption: asset.caption,
        link: asset.link,
        assetType: asset.assetType,
        callToAction: asset.callToAction,
        createdAt: asset.createdAt
      })),
    }
  });
}

export default function useFacebookAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()
  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    generateStackedChartOptions,
  } = useAnalytics(defaultLabel)


  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      // Check if there's an ongoing request of the same type and abort it

      if (ongoingRequests.has(type)) {
        ongoingRequests.get(type).abort();
      }

      // Create a new AbortController for the new request
      const controller = new AbortController();
      const { signal } = controller;
      ongoingRequests.set(type, controller);

      try {
        setIsloading(type, true);
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          facebook_id: getAccountId(selectedAccount.value),
          facebook_accounts:[],
          twitter_accounts: [],
          instagram_accounts: [],
          pinterest_accounts: [],
          linkedin_accounts: [],
          tiktok_accounts: [],
          youtube_accounts: [],
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          type,
          ...extraPayload,
        };
        const { data } = await proxy.post(
          BASE_ENDPOINT+'facebook/' + type,
          payload,
          { signal }
        );

        // Handle the data based on the type
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data.overview;
            break;
          case routes.OVERVIEW_AUDIENCE_GROWTH:
            audienceGrowthData.value = data;
            break;
          case routes.OVERVIEW_REACH:
            reachData.value = data;
            break;
          case routes.OVERVIEW_ACTIVE_USERS:
            activeUsersData.value = data?.active_users;
            break;
          case routes.OVERVIEW_IMPRESSIONS:
            impressionsData.value = data;
            break;
          case routes.OVERVIEW_ENGAGEMENT:
            engagementsData.value = data?.engagement;
            break;
          case routes.OVERVIEW_TOP_POSTS:
            overviewTopPostsData.value = data?.top_posts || [];
            break;
          case routes.OVERVIEW_AUDIENCE_LOCATION:
            overviewAudienceLocationData.value = data || { audience_city: {}, audience_country: {} };
            break;
          case routes.OVERVIEW_DEMOGRAPHICS:
            overviewDemographicsData.value = data || {};
            break;
          case routes.OVERVIEW_REELS_ANALYTICS:
            overviewReelsAnalyticsData.value = data || {};
            break;
          case routes.OVERVIEW_VIDEO_INSIGHTS:
            overviewVideoInsightsData.value = data || {};
            break;
          case routes.OVERVIEW_PUBLISHING_BEHAVIOUR:
            overviewPublishingBehaviourData.value = data || {};
            break;
          case routes.TOP_POSTS:
            topPostsData.value = transformObject(data?.top_posts) || [];
            break;
          default:
            break;
        }
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log(`Request for ${type} was aborted`);
          return;
        } else {
          console.error('FETCH DATA:::', e);
        }
      }
        setIsloading(type, false);
        // Clean up the ongoing request map
        ongoingRequests.delete(type);
    }
  };

  const getTimeFormatted = (timeString) => {
    return moment(timeString).format('MMMM Do, YYYY hh:mm A')
  }

  return {
    ANALYTICS_DELIMITER,
    analyticsDesignSystem,
    // variables
    routes,
    rollupTypes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,
    postsLimit,
    postModalFields,
    tableSortableItems,
    // state
    fanCount,
    selectedAccount,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    TopLeastEngagementDropdown,
    selectedTopLeastSortType,
    isReportView,
    screenWidth,
    isLoadingStates,
    audienceGrowthData,
    reachData,
    activeUsersData,
    impressionsData,
    engagementsData,
    overviewTopPostsData,
    overviewAudienceLocationData,
    overviewDemographicsData,
    overviewReelsAnalyticsData,
    overviewVideoInsightsData,
    overviewPublishingBehaviourData,
    topPostsData,
    audienceRollupKeys,
    reachRollupKeys,
    impressionsRollupKeys,
    engagementsRollupKeys,
    validPostsTableHeaders,
    validPostsTableHeadersReport,
    nonSortableItems,
    mediaTypeMap,

    cardsData,
    // methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getTimeFormatted,
    tooltipFormatterScroll,
    setIsloading,
    getRollup,
    getHeaderTitles,
    getHeaderTooltips,
    getBodyValues,
    getHeaderApiKey,
    generateStackedChartOptions,
  }
}
