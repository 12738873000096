<script setup>
import {
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import usePermissions from '@common/composables/usePermissions'
import LayoutCard from '@modules/setting/components/white-label/LayoutCard.vue'
import MaterialGuide from '@modules/setting/components/white-label/MaterialGuide.vue'
import CstSwitch from '@ui/Switch/CstSwitch.vue'
import { useStore } from '@state/base'
import { useRouter } from '@src/router'
import useDomainSettings from '@modules/setting/composables/whitelabel/useDomainSettings'
import useGeneralSettings from '@modules/setting/composables/whitelabel/useGeneralSettings'
import useEmailSettings from '@modules/setting/composables/whitelabel/useEmailSettings'
import SettingsIcon from '@assets/img/settings/white-label/settings.svg'
import EmailIcon from '@assets/img/settings/white-label/email.svg'
import DomainIcon from '@assets/img/settings/white-label/domain.svg'
import CompletionPopup from '@modules/setting/components/white-label/CompletionPopup.vue'

const GeneralSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/GeneralSettings.vue')
)
const DomainSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/DomainSettings.vue')
)
const EmailSettings = defineAsyncComponent(() =>
  import('@modules/setting/components/white-label/EmailSettings.vue')
)

const { getters } = useStore()
const router = useRouter()

const {
  currentStep,
  enableWhiteLabel,
  isSetupComplete,
  whiteLabelUrl,
  goToStep,
  nextStep,
  prevStep,
  toggleWhiteLabelActiveStatus,
  fetchWhiteLabel,
  resetLocalStates,
} = useWhiteLabel()

const {
  shouldDisableNext: disableGeneralSettingsNavigation,
  handleSubmit: saveGeneralSettings,
} = useGeneralSettings()
const {
  shouldDisableNext: disableDomainSettingsNavigation,
  handleSubmit: saveDomainSettings,
} = useDomainSettings()
const {
  completionStatus,
  shouldDisableNext: disableEmailSettingsNavigation,
  handleSubmit: saveEmailSettings,
} = useEmailSettings()

const { hasPermission } = usePermissions()

const showCopiedMessage = ref(false)

const steps = [
  { name: 'General Settings', component: GeneralSettings },
  { name: 'Domain Settings', component: DomainSettings },
  { name: 'Email Settings', component: EmailSettings },
]

// classes for the steps and icons
const stepColors = ['!text-[#7272FF]', 'text-[#4CCE88]', 'text-[#FF9472]']

const stepsBorderColor = [
  '!border-[#7272FF]',
  '!border-[#4CCE88]',
  '!border-[#FF9472]',
]

const stepIcons = [SettingsIcon, DomainIcon, EmailIcon]

const disabledSteps = computed(() => {
  if (disableGeneralSettingsNavigation.value) {
    return [false, true, true]
  } else if (disableDomainSettingsNavigation.value) {
    return [false, false, true]
  } else if (disableEmailSettingsNavigation.value) {
    return [false, false, false]
  } else {
    return [false, false, false]
  }
})

onMounted(async () => {
  if (!hasPermission('white_label')) {
    await router.push({
      name: 'dashboard',
      params: { workspace: getters.getActiveWorkspace.slug },
    })
  }
  await fetchWhiteLabel()
})

onBeforeUnmount(() => {
  resetLocalStates()
})

const handleWhiteLabelStatusChange = () => {
  toggleWhiteLabelActiveStatus()
}

const copyToClipboard = () => {
  if (isSetupComplete.value) {
    navigator.clipboard.writeText(whiteLabelUrl.value).then(() => {
      showCopiedMessage.value = true
      setTimeout(() => {
        showCopiedMessage.value = false
      }, 2000)
    })
  }
}

const openInNewTab = () => {
  if (isSetupComplete.value) {
    window.open(whiteLabelUrl.value, '_blank', 'noopener,noreferrer')
  }
}

const handleStepClick = async (index) => {
  if (!disabledSteps.value[index]) {
    if (index > currentStep.value) {
      // Save current step data when moving to a next step
      if (currentStep.value === 0) {
        await saveGeneralSettings()
      } else if (currentStep.value === 1) {
        await saveDomainSettings()
      } else if (currentStep.value === 2) {
        await saveEmailSettings()
      }
    }
    goToStep(index)
  }
}

const handleNextStep = () => {
  const nextStepIndex = currentStep.value + 1
  if (nextStepIndex < steps.length && !disabledSteps.value[nextStepIndex]) {
    nextStep()
  }
}
</script>

<template>
  <div class="container relative my-8 pr-8">
    <LayoutCard custom-classes="mb-7">
      <template v-slot:body>
        <p class="text-xl font-bold mb-2 text-center text-[#3A4557]"
          >White Label Agency Setup</p
        >
        <p class="text-center text-[#595C5F] text-md-center"
          >Adjust your custom branding according to your brand</p
        >
        <div
          class="flex pt-5 mb-4 mx-auto w-[80%] items-center justify-between"
        >
          <div
            v-for="(step, index) in steps"
            :key="index"
            :class="[
              disabledSteps[index]
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-pointer',
            ]"
            class="flex items-center"
            @click="handleStepClick(index)"
          >
            <div
              :class="[
                'w-11 h-11 rounded-full flex items-center justify-center border text-xl',
                currentStep === index
                  ? stepsBorderColor[index]
                  : '!border-gray-300',
              ]"
            >
              <img :src="stepIcons[index]" alt="" class="w-5 h-5" />
            </div>
            <span
              :class="[
                currentStep === index ? `font-medium ${stepColors[index]}` : '',
              ]"
              class="ml-2 mr-4"
            >
              {{ step.name }}
            </span>
            <div
              v-if="index < steps.length - 1"
              class="w-16 h-px border !border-dashed"
            ></div>
          </div>
        </div>
      </template>
    </LayoutCard>

    <div class="relative">
      <LayoutCard>
        <template v-slot:header>
          <div class="flex justify-between items-center">
            <p class="font-medium text-lg">{{ steps[currentStep].name }}</p>
            <div class="flex gap-2">
              <div class="relative flex items-center">
                <span
                  v-if="showCopiedMessage"
                  class="bg-gray-800 text-white text-xs rounded py-1 px-2 mr-2"
                >
                  Copied!
                </span>
                <i
                  v-tooltip="'Copy to clipboard'"
                  :class="{ 'opacity-50 cursor-not-allowed': !isSetupComplete }"
                  class="far fa-link text-blue-500 cursor-pointer"
                  @click="copyToClipboard"
                />

                <a
                  v-tooltip="'Open in new tab'"
                  :class="{
                    'hover:underline': isSetupComplete,
                    'opacity-50 cursor-not-allowed': !isSetupComplete,
                  }"
                  :href="whiteLabelUrl"
                  class="ml-1 text-blue-500 transition-colors duration-200"
                  @click.prevent="openInNewTab"
                >
                  White Label URL
                </a>
              </div>
              <CstSwitch
                v-model="enableWhiteLabel"
                v-tooltip="{
                  content: enableWhiteLabel
                    ? 'Contact Support to disable White Label'
                    : 'Enable White Label',
                }"
                :disabled="!isSetupComplete || enableWhiteLabel"
                size="small"
                @input="handleWhiteLabelStatusChange"
              />
            </div>
          </div>
        </template>
        <template v-slot:body>
          <component
            :is="steps[currentStep].component"
            @next="handleNextStep"
            @prev="prevStep"
          />
        </template>
      </LayoutCard>
      <MaterialGuide />
    </div>
    <CompletionPopup :status="completionStatus" />
  </div>
</template>
