<script setup>
import { computed } from 'vue'
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import CardsComponent from '@src/modules/analytics/views/facebook_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/facebook_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/facebook_v2/components/graphs/AudienceGrowthChart.vue'
import ImpressionsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/ImpressionsChart.vue'
import EngagementsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/EngagementsChart.vue'
import ActiveUserByHour from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByHour.vue'
import ActiveUserByDay from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByDay.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import ReelsPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/ReelsPerformanceChart.vue'
import RollupCard from '@/src/modules/analytics/views/facebook_v2/components/RollupCard.vue'
import VideoPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/VideoPerformanceChart.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/PublishingBehaviourChart.vue'
import PostsSection from "@/src/modules/analytics/views/facebook_v2/components/PostsSection.vue";
import AudienceLocationChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/AudienceLocationChart.vue'
import DemographicsByGender from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByGender.vue'
import DemographicsByAge from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByAge.vue'

const GENDER_MAP = {
  M: 'Males',
  F: 'Females',
  U: 'Unspecified',
}

const {
  rollupTypes,
  topPostsData,
  overviewDemographicsData,
} = useFacebookAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  if (topPostsData.value) {
    for (let i = 0; i < topPostsData.value?.length; i += chunkSize) {
      startIndexes.push(i)
    }
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const TOTAL_SECTIONS = 9
  const demographicsSection = 1

  if(hasDemographicsData.value) {
    return TOTAL_SECTIONS + topEngagingPostsChunks.value.length + demographicsSection
  }

  return TOTAL_SECTIONS + topEngagingPostsChunks.value.length
})

const leadingGender = computed(() => {
  return GENDER_MAP[overviewDemographicsData.value?.max_gender_age?.gender] || 'Unknown'
})
const hasDemographicsData = computed(() => {
  return overviewDemographicsData.value?.fans > 100
})

const getActualPageNumber = (number) => {
  console.log('hasDemographicsData', hasDemographicsData.value)
  if(!hasDemographicsData.value) {
    return number - 1
  }
  return number
}

</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col">
          <!-- Cards -->
          <div class="grid grid-cols-8 gap-4 mb-14">
            <CardsComponent />
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
              <AudienceGrowthChart type="daily" />
            </div>
            <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="2"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-1">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <AudienceGrowthChart type="cumulative" />
          </div>
          <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>

        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
            <ImpressionsChart />
            </div>
          <RollupCard :type="rollupTypes.IMPRESSIONS" />
        </div>

        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
              <EngagementsChart />
            </div>
          <RollupCard :type="rollupTypes.ENGAGEMENTS" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="3"
        :total="totalPageSize"
      ></ReportHeader>
        <div class="flex flex-col gap-7">
          <PublishingBehaviourChart type="impressions" />
          <PublishingBehaviourChart type="engagements" />
        </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="4"
        :total="totalPageSize"
      ></ReportHeader>
        <div class="flex flex-col gap-7">
          <PublishingBehaviourChart type="reach" />
          <div class="grid grid-cols-2 gap-4">
            <ActiveUserByHour/>
            <ActiveUserByDay/>
          </div>
        </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="5"
        :total="totalPageSize"
      ></ReportHeader>
      <TopPosts />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="6"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <VideoPerformanceChart type="watch_time" />
          </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>

        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
            <VideoPerformanceChart type="views" />
            </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="7"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
              <VideoPerformanceChart type="engagements" />
            </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>
        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
              <ReelsPerformanceChart type="initial" />
            </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="8"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="watch_time" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>

        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2">
            <ReelsPerformanceChart type="engagement" />
            </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
  </div>
  <template v-if="hasDemographicsData">
    <div class="page_view">
      <div class="subpage">
        <ReportHeader
          :report-name="'Facebook'"
          :current="9"
          :total="totalPageSize"
        ></ReportHeader>
        <div class="flex flex-col gap-7">
          <div class="w-full text-white p-3 bg-[black] mb-2 rounded-md">Meta has deprecated <span class="font-bold">Facebook Audience by Age and Gender</span> data.
            Insights prior to <span class="font-bold">March 14, 2024</span>, are available if your account was connected before that date.
          </div>
          <!-- Demographics Chart -->
          <!-- fans value is always 0 for accounts connected after 14 march 2024 so hiding for accounts connected after that -->
          <div  class="grid grid-cols-1 gap-4 ">
            <DemographicsByAge />
            <DemographicsByGender />

            <div class="bg-white p-5 rounded-md w-full relative flex justify-center color-border cst-editor ">
              <p>
                <span class="font-semibold"> {{ leadingGender }}</span> between the ages of <span class="font-semibold"> {{ overviewDemographicsData?.max_gender_age?.age }}</span>  appear to be the leading force among your followers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Facebook'"
        :current="getActualPageNumber(10)"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <div class="grid grid-cols-1 gap-7">
          <AudienceLocationChart type="country"/>
          <AudienceLocationChart type="city" />
        </div>
      </div>
    </div>
  </div>
  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view">
      <div class="subpage">
        <!-- Render report header -->
        <ReportHeader
          :report-name="'Facebook'"
          :current="getActualPageNumber(11 + index)"
          :total="totalPageSize"
        ></ReportHeader>
        <!-- Render post table for the current chunk of top posts -->
        <PostsSection
          :range-start-value="start"
          :range-end-value="
            Math.min(start + chunkSize, topPostsData?.length)
          "
        />
      </div>
    </div>
  </template>

</template>
