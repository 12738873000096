<script setup>
import { computed, watch, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'

// state
const {
  overviewDemographicsData,
  dataZoomOptions,
  pieChartOptions: chartOptions,
  isReportView,
  fanCount,
} = useFacebookAnalytics('Demographics Gender')

const itemsMap = {
  M: {name:'Male', color: '#8ABAF3'},
  F: {name:'Female', color: '#ff7b7c'},
  U: {name:'Unspecified', color: '#8AC1A2'},
}

const isNoData = computed(() => {
  const data = overviewDemographicsData.value?.audience_gender || {}
  const isAllZero = Object.values(data).every((value) => value === 0)
  return (Object.keys(data).length === 0) || isAllZero
})

const formatDataForChart = (data) => {
  return Object.keys(data).map(key => ({
    value: data[key],
    name: itemsMap[key].name,
    itemStyle: {
      color: itemsMap[key].color
    }
  }));
};

watch(
  () => overviewDemographicsData.value,
  () => {
    const options = overviewDemographicsData.value?.audience_gender || {}
    chartOptions.value.series[0].data = formatDataForChart(options) || []
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = ref(false)

</script>

<template>
  <AnalyticsCardWrapper type="fb-gender-demographics" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Audience by Gender
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Reach is the number of people who saw your post. It is a unique metric that measures the number of people who saw your content. It is important to track reach because it helps you understand how many people are seeing your content and how well it is performing.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="pie"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p v-if="fanCount <= 100">You'll need 100 followers (who aren't your friends) to view this demographic information.</p>
          <p v-else>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
