<template>
  <div
    class="w-full bg-[#FBFCFC] text-black-900 flex flex-col h-full overflow-hidden"
    style="max-height: calc(100% - 60px)"
  >
    <!-- Chat messages -->
    <div
      ref="conversationBox"
      class="flex-col w-full flex-1 p-4 justify-between overflow-auto scroll-smooth relative"
      style="overscroll-behavior: none"
    >
      <template v-if="props.activeChat.messages.length > 0">
        <template
          v-for="(message, index) in props.activeChat.messages"
          :key="index"
        >
          <component
            :is="message.role === 'user' ? UserChatTemplate : BotChatTemplate"
            :message="message"
            :type="props.type"
            :save-prompt="props.handleCustomPrompts"
            :delete-message="props.deleteMessage"
          />
        </template>
        <div v-if="props.messageLoader" class="flex justify-center w-full pb-2">
          <div
            class="py-2 px-3 inline-flex justify-center items-center rounded"
            style="box-shadow: 0 1px 8px 0 rgba(0, 27, 70, 0.06)"
          >
            <beatLoader :color="'#436aff'" size="8px" class="h-4" />
          </div>
        </div>
        <div ref="lastMessageDiv" class="opacity-0" />
      </template>
      <template v-else-if="props.activeChatLoader">
        <div class="absolute bottom-0 left-[48%]">
          <beatLoader size="8px" :color="'#436aff'" />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-24 w-full text-center flex-1 flex flex-col items-center justify-center px-4"
        >
          <img
            src="@src/assets/img/chat_bot/cyrus-icon-blue-light.svg"
            class="w-[9.25rem]"
            alt=""
          />
          <div class="whitespace-nowrap font-medium text-lg mt-4"
            >Hey {{ getUserName }}!</div
          >
          <div class="text-gray-700 font-normal text-base"
            >How may I help you today?</div
          >
        </div>
      </template>
    </div>

    <!-- Quick prompts and input -->
    <div
      id="quick-prompts"
      ref="quickPromptsRef"
      class="bg-[#FBFCFC] w-full"
      style="border-radius: 0 0 8px 8px"
    >
      <div class="m-2 border border-gray rounded-md p-3">
        <div class="flex items-center justify-between py-2">
          <div class="flex items-center">
            <span
              class="font-weight-500 cursor-pointer"
              :class="{
                'text-[#117CFD]': activeTab === 'quick',
                'text-[#3A4557]': activeTab !== 'quick',
              }"
              @click="activeTab = 'quick'"
            >
              Quick Prompts
            </span>
            <span class="text-gray-500 text-sm mx-4">|</span>
            <span
              class="font-weight-500 cursor-pointer"
              :class="{
                'text-[#117CFD]': activeTab === 'favourites',
                'text-[#3A4557]': activeTab !== 'favourites',
              }"
              @click="activeTab = 'favourites'"
            >
              Favourites
            </span>
          </div>
          <i
            v-if="activeTab === 'favourites' && hasOverflow"
            class="fa fa-chevron-up cursor-pointer text-xs text-gray-700"
            :class="{ 'rotate-180': !expanded }"
            @click="toggleExpand"
          />
        </div>

        <div
          ref="promptsContainer"
          class="mt-1 mb-2 transition-all duration-300 min-h-[66px]"
          :style="{
            maxHeight: expanded ? '150px' : '100px',
            overflow: expanded ? 'auto' : 'hidden',
          }"
        >
          <div class="flex flex-wrap gap-1.5">
            <template v-if="activeTab === 'quick'">
              <div
                v-for="prompt in AiChatQuickPrompts"
                :key="prompt"
                v-tooltip="prompt"
                class="bg-[#F4F6FA] text-sm text-[#000D21] px-3 py-1.5 rounded-[0.375rem] cursor-pointer hover:bg-[#ECEEF5]"
                @click="handlePromptClick(prompt)"
              >
                {{ prompt }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="prompt in props.prompts"
                :key="prompt.title"
                v-tooltip="prompt.prompt"
                class="text-sm text-[#000D21] px-3 py-1.5 rounded-[0.375rem] cursor-pointer"
                :style="{
                  backgroundColor: prompt.color_code || '#F4F6FA',
                  border: `1px solid ${
                    clickedPrompt === prompt._id ? '#2961D2' : '#F5F5F5'
                  }`,
                }"
                @click="handlePromptClick(prompt, 'custom')"
              >
                <span class="prompt-title">{{ prompt.title }}</span>
              </div>
              <template v-if="!props.prompts?.length">
                <div class="flex justify-center items-center w-full h-[65px]">
                  <span class="text-lg">No favourites prompt found!</span>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div
          v-if="activeTab === 'favourites' && hasOverflow"
          class="flex justify-end items-center"
        >
          <div
            class="text-blue-500 text-sm cursor-pointer"
            @click="toggleExpand"
          >
            {{ expanded ? 'View less' : 'View more' }}
          </div>
        </div>
      </div>

      <!-- Input area -->
      <div
        class="px-4 py-4 w-full min-h-[10rem] bg-[#F4F6FA] relative"
        style="border-top: 1px solid #eaf0f6"
      >
        <div
          class="bg-white p-4 rounded-lg"
          style="box-shadow: 0 4px 15px -5px rgba(25, 0, 125, 0.1)"
        >
          <div
            ref="editableDiv"
            contenteditable="true"
            class="chatInput resize-none w-full border-0 text-base min-h-[45px] h-full rounded-lg !max-h-[225px] overflow-auto"
            placeholder="Type your message here"
            @input="updateContent"
            @keydown="handleKeyEvents"
          />
          <div
            class="mt-4 w-full flex items-center justify-between bg-white rounded-lg"
          >
            <p
              class="text-[#0068E5] text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none"
              style="border: 1px solid #0068e5"
              @click="props.toggleChatDialogs('openSavedPrompts')"
            >
              Prompts
            </p>
            <p
              class="bg-cs-primary text-white text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none flex items-center gap-x-2 hover:bg-opacity-75"
              :class="{
                'opacity-50 !cursor-not-allowed':
                  props.activeChatLoader || props.messageLoader,
              }"
              style="border: 1px solid #0068e5"
              @click="handleSendMessage"
            >
              Send
              <img
                src="@src/assets/img/chat_bot/send_icon.svg"
                alt="send_icon"
                width="14"
                height="14"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { AiChatQuickPrompts } from '@src/modules/AI-tools/Prompts.js'
import { useStore } from '@state/base'
import BotChatTemplate from '@src/modules/AI-tools/BotChatTemplate.vue'
import UserChatTemplate from '@src/modules/AI-tools/UserChatTemplate.vue'
import { EventBus } from '@common/lib/event-bus'

const store = useStore()

const props = defineProps({
  type: {
    type: String,
    default: 'modal',
  },
  modalToggler: {
    type: Boolean,
    default: false,
  },
  activeChatLoader: {
    type: Boolean,
    default: false,
  },
  messageLoader: {
    type: Boolean,
    default: false,
  },
  activeChat: {
    type: Object,
    default: () => ({}),
  },
  fetchActiveChat: {
    type: Function,
    default: () => {},
  },
  sendMessage: {
    type: Function,
    default: () => {},
  },
  deleteMessage: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
  promptInput: {
    type: String,
    default: '',
  },
  isCreditVisible: {
    type: Boolean,
    default: false,
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  prompts: {
    type: Array,
    default: () => [],
  },
})

const quickPromptsRef = ref(null)
const lastMessageDiv = ref(null)
const conversationBox = ref(null)
const editableDiv = ref(null)
const previousContent = ref('')
const activeTab = ref('quick')
const expanded = ref(false)
const clickedPrompt = ref(null)
const promptsContainer = ref(null)
const hasOverflow = ref(false)
const removeBG = ref(false)

const getUserName = computed(() => store.getters.getProfile?.firstname)

const handleKeyEvents = (event) => {
  if (props.activeChatLoader || props.messageLoader) return
  if (!event.shiftKey && event.key === 'Enter') {
    event.preventDefault()
    handleSendMessage()
  }
}

const highlightAndSetContent = (content) => {
  const highlightedContent = content.replace(
    /\[([^\]]+)\]/g,
    '<span style="background-color: #DCECFF; padding: 2px 4px; border-radius: 4px;">[$1]</span>'
  )
  editableDiv.value.innerHTML = highlightedContent
  previousContent.value = editableDiv.value.innerText
  const range = document.createRange()
  const sel = window.getSelection()
  range.selectNodeContents(editableDiv.value)
  range.collapse(false)
  sel.removeAllRanges()
  sel.addRange(range)
  editableDiv.value.focus()
  removeBG.value = false
}

const handleSendMessage = () => {
  if (props.activeChatLoader || props.messageLoader) return
  const content = editableDiv.value.innerText.trim()
  if (content !== '' && !props.activeChatLoader) {
    props.sendMessage(content)
    editableDiv.value.innerHTML = ''
    scrollToLastMessage()
  }
}

const scrollToLastMessage = () => {
  const ele = conversationBox.value
  if (ele) {
    setTimeout(() => {
      ele.scrollTop = ele.scrollHeight
    }, 30)
  }
}

const scrollSmooth = () => {
  const ele = lastMessageDiv.value
  if (ele) {
    setTimeout(() => {
      ele.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  } else {
    scrollToLastMessage()
  }
}

const updateContent = (event) => {
  const currentContent = event.target.innerText
  if (currentContent !== previousContent.value && !removeBG.value) {
    editableDiv.value.innerHTML = currentContent
    previousContent.value = currentContent
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(editableDiv.value)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
    removeBG.value = true
  }
}

const handlePromptClick = (prompt, type = 'quick') => {
  clickedPrompt.value = prompt._id
  highlightAndSetContent(type === 'custom' ? prompt.prompt : prompt)
}

const toggleExpand = () => {
  expanded.value = !expanded.value
}

const checkOverflow = () => {
  if (promptsContainer.value) {
    hasOverflow.value = promptsContainer.value.scrollHeight > 110
  }
}

watch(activeTab, () => {
  nextTick(() => {
    checkOverflow()
  })
})

watch(
  () => props.prompts,
  () => {
    nextTick(() => {
      checkOverflow()
    })
  },
  { deep: true }
)

watch(
  () => props.activeChat,
  () => {
    scrollToLastMessage()
    editableDiv.value?.focus()
  },
  { deep: true }
)

watch(() => props.activeChatLoader, scrollSmooth)

watch(
  () => props.modalToggler,
  (value) => {
    if (value) {
      props.fetchActiveChat()
      scrollSmooth()
    }
  }
)

watch(
  () => store.getters.getWorkspaces.activeWorkspace._id,
  (newValue) => {
    if (newValue) props.fetchActiveChat()
  },
  { immediate: true }
)

onMounted(() => {
  EventBus.$on('append-prompt', (value) => {
    const promptText = value.prompt ?? value
    highlightAndSetContent(promptText)
  })
  checkOverflow()
})
</script>

<style lang="scss">
.ai-chat-message {
  .chat-codeblock {
    color: #000000ad;
    font-weight: 300;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    display: block;
    word-wrap: break-word;
  }
  .chat-hashtag {
    color: #007bff;
  }
}
span.prompt-title {
  display: block;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatInput[contenteditable='true']:empty:before {
  content: attr(placeholder);
  color: #aaa;
}
.chatInput span[style*='background-color: #E6F3FF'] {
  display: inline-block;
  line-height: 1.2;
  margin: 0 2px;
}
</style>
