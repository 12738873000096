<script setup>
import VideoIntro from '@src/modules/account/views/onboarding/VideoIntro.vue'
import { onMounted, onUnmounted, inject, ref } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import CstButton from "@ui/Button/CstButton.vue";

const root = inject('root')

const { $bvModal } = root

const videoID = ref('')

onMounted(() => {
  EventBus.$on('show-yt-video-modal', (sourceID) => {
    videoID.value = sourceID
    $bvModal.show('yt-video-modal')
  })
})

onUnmounted(() => {
  EventBus.$off('show-yt-video-modal')
})
</script>

<template>
  <b-modal
    id="yt-video-modal"
    body-class="!p-0"
    dialog-class="xl:!max-w-[80rem]"
    content-class="!rounded-2xl"
    size="xl"
    centered
    hide-footer
  >
    <div class="bg-gray-50 lg:!bg-transparent lg:h-[41rem] rounded-2xl">
      <VideoIntro :video-id="videoID" />
    </div>
    <div class="flex justify-center align-items-center h-[5rem] px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <p class="text-[#3C4549] text-center font-semibold">
        Watch this quick tutorial to know how ContentStudio can transform your productivity.
      </p>
    </div>
  </b-modal>
</template>

<style lang="scss">
#yt-video-modal {
  .modal-dialog {
    .modal-content {
      background: transparent !important;
    }

    .modal-header {
      padding: 0 !important;
      background: transparent !important;
      border: none !important;
      position: relative;

      .close {
        position: absolute;
        background: white;
        margin: 0 !important;
        right: -10px;
        top: -10px;
        z-index: 9999;
        border-radius: 100% !important;
        width: 29px !important;
        height: 10px !important;
        padding-bottom: 16px !important;
      }
    }
  }
}
</style>
