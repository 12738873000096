<template>
  <div class="flex justify-center w-full h-full items-center flex-col">
    <clip-loader
      v-if="isLoading"
      class="spinner"
      :color="'#e0dfdf'"
      :size="'48px'"
    ></clip-loader>
    <iframe
      :src="getSrc()"
      width="500"
      class="border-0 h-full"
      :class="{ 'invisible h-1': isLoading }"
      title="Facebook Post Preview"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      :onload="() => isLoading = false"
    ></iframe>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  containerId: {
    type: String,
    required: true,
  },
  postLink: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: '500px',
  },
  dataShowText: {
    type: Boolean,
    default: true,
  },
  dataLazy: {
    type: Boolean,
    default: true,
  },
})

const isLoading = ref(true)

const getSrc = () => {
  return `https://www.facebook.com/plugins/post.php?href=${props.postLink}&width=${props.width}&show_text=${props.dataShowText}&appId`
}
</script>
